/**
 *
 * IndicatorMatrix
 *
 */

import Select from '../Select';
import { useMemo } from 'react';
import {
	Box,
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	SystemStyleObject,
	Tag,
	Text,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DownloadPdfIconModal, SighMainLogoIconModal } from 'assets/icons';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { downloadMatrixIndicator, getIndicatorMatrix } from 'services/http/indicators';
import { IResponseAttributionAgreedInIndicator, IResponseIndicatorById } from 'types/indicators';
import { IOption } from 'types/select';
import {
	API_DEFAULT_ERROR,
	BIMESTERS_ENUM,
	POLARITIES,
	POLARITY_STATUS,
	TEXT_DATA_CONTROL_STEPS_ENUM,
	UNIDADEMEDIDA,
} from 'utils/constants';
import { RESPONSAVELLANCAMENTO } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';

export interface IndicadorMatrixForm {
	pactuado?: IOption<IResponseAttributionAgreedInIndicator>;
}

interface IndicatorMatrixProps {
	indicatorId: string;
	id?: string;
	onClose: () => void;
	isOpen: boolean;
	isEditing?: boolean;
	showPactuado?: boolean;
}

const IndicatorMatrix = ({ id, indicatorId, onClose, isOpen, isEditing, showPactuado }: IndicatorMatrixProps) => {
	const styles: Record<string, SystemStyleObject> = {
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		principal: {
			maxWidth: '68.75rem',
			w: '100%',
			alignItems: 'center',
			padding: '2rem 3rem',
			margin: '2.5rem',
		},
		header: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			flexDirection: { base: 'column', lg: 'row' },
			alignItems: { base: 'baseline', lg: 'center' },
		},
		icon: {
			width: '100%',
			alignContent: 'center',
		},
		fasePactuadoContent: {
			maxW: {
				base: '100%',
				lg: '28rem',
			},
			w: '100%',
		},
		title: { fontSize: '1.5rem', fontWeight: 600, textAlign: 'center' },
		boxB: { m: { base: '1.5rem 0', lg: '0 17.813rem' } },
		head: {
			flexDirection: { base: 'column', lg: 'row' },
			m: { base: '0', lg: '1.188rem 0' },
			gap: '0.625rem',
			alignItems: { base: 'baseline', lg: 'center' },
		},
		headText: { fontSize: '1.5rem', fontWeight: 800 },
		headTag: {
			fontSize: '0.75rem',
			borderRadius: '0.75rem',
			h: '0.938rem',
			backgroundColor: 'blue.400',
			minW: '3.531rem',
		},
		gridContainer: {
			p: '1.5rem 0',
			gap: '1.5rem',
			rowGap: '1.5rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
		},
		gridContainerBottom: {
			gap: '1.5rem',
			rowGap: '1.5rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
		},
		flexContainerTablePeriod: {
			height: { base: 'auto', lg: '5rem' },
			display: 'flex',
			flexDirection: { base: 'row', lg: 'column' },
			flexWrap: 'wrap',
			flex: '1 1 4.375rem',
		},
		gridContainerFirst: {
			maxW: { base: '100%', md: '39rem', lg: '28.875rem' },
			w: { base: '100%', lg: '28.875rem' },
			borderRadius: '0.25rem',
			bgColor: '#E6E4E4',
			p: '1rem 1.5rem',
			minHeight: '13.875rem',
		},
		gridContainerSecond: {
			maxW: { base: '100%', md: '39rem', lg: '28.875rem' },
			w: { base: '100%', lg: '28.875rem' },
			borderRadius: '0.25rem',
			bgColor: '#F0F0F0',
			p: '1rem 1.5rem',
			minHeight: '13.875rem',
		},
		gridContainerSecondTwo: {
			maxW: { base: '100%', md: '39rem', lg: '28.875rem' },
			w: { base: '100%', lg: '28.875rem' },
			borderRadius: '0.25rem',
			bgColor: '#F0F0F0',
			p: '1rem 1.5rem',
			minHeight: '6.438rem',
		},
		box: {
			flexDirection: 'column',
			gap: '1.5rem',
			rowGap: '1.5rem',
		},
		firstBox: {
			maxW: { base: '100%', md: '39rem', lg: '59.25rem' },
			w: '100%',
			borderRadius: '0.25rem',
			bgColor: '#E6E4E4',
			p: '1rem 1.5rem',
			minHeight: '9.75rem',
		},
		titleSecondBox: {
			display: 'flex',
			maxW: '59.25rem',
			w: '100%',
			color: 'white',
			borderRadius: '0.25rem',
			p: '1rem 1.5rem',
			bgColor: '#0A77D2',
			minHeight: '2.188rem',
			fontSize: '1rem',
			fontWeight: 700,
			alignItems: 'center',
			justifyContent: 'flex-start',
			textAlign: 'left',
		},
		titleThirdBox: {
			display: 'flex',
			maxW: '59.25rem',
			color: 'white',
			borderRadius: '0.25rem',
			p: '1rem 1.5rem',
			bgColor: '#004A73',
			minHeight: '2.188rem',
			fontSize: '1rem',
			fontWeight: 700,
			alignItems: 'center',
			justifyContent: 'flex-start',
			textAlign: 'left',
		},
		secondBox: {
			maxW: { base: '100%', md: '39rem', lg: '59.25rem' },
			w: '100%',
			borderRadius: '0.25rem',
			p: '1rem 1.5rem',
			bgColor: '#ddeffd',
			minHeight: '8rem',
		},
		thirdBox: {
			display: 'flex',
			flexDirection: { base: 'column', lg: 'row' },
			maxW: { base: '100%', md: '39rem', lg: '59.25rem' },
			w: '100%',
			borderRadius: '0.25rem',
			p: '1rem',
			bgColor: '#ddeffd',
			minHeight: '8rem',
			rowGap: { base: '0.5rem', lg: '0.1rem' },
			gap: { base: '1rem', lg: '16rem' },
		},
		textBoxGrid: {
			fontSize: '0.938rem',
			mr: '0.75rem',
			whiteSpace: 'pre-line',
		},
		textBoxGrid2: {
			fontSize: '0.938rem',
			fontWeight: 700,
			textAlign: { base: 'start', lg: 'end' },
		},
		textBoxGrid3: {
			fontSize: '0.938rem',
			fontWeight: 700,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: '0.75rem',
		},
		textBoxGrid4: {
			fontSize: '0.938rem',
			fontWeight: 700,
			width: '30%',
		},
		textBoxGridPact: {
			fontSize: '0.938rem',
			width: '30%',
		},
		footerContent: {
			maxW: { base: '100%', lg: '20rem' },
			w: '100%',
			justifyContent: 'center',
			gap: { base: '1.5rem', sm: '1.875rem' },
			flexDirection: { base: 'column', sm: 'row' },
		},
		button: { fontSize: '1rem', h: '2.75rem', w: '100%' },
		modalCloseBtn: {
			margin: '2.5rem 4.375rem',
			padding: '0.625rem',
		},
		modalFlexContainer: {
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: '0.469rem 0',
		},
		modalBoxContainer: {
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			flexDirection: 'column',
			gap: '1rem',
		},
		modalSecBoxContainer: {
			justifyContent: 'left',
			alignItems: 'center',
			gap: '0.5rem',
			paddingBottom: '0.5rem',
		},
		modalTerBoxContainer: {
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			flexDirection: 'column',
			gap: '0.5rem',
		},
	};

	const { addToast } = useCustomToast();

	const { control, watch } = useForm<IndicadorMatrixForm>();

	const { data: indicator, isLoading: isLoadingIndicator } = useQuery<
		IResponseIndicatorById,
		AxiosError<ResponseErrors>,
		IResponseIndicatorById
	>(['indicador', indicatorId, isOpen], () => getIndicatorMatrix(indicatorId), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
		enabled: !!indicatorId && isOpen,
	});

	const pactuados = useMemo(() => indicator?.pactuados?.map(item => item), [indicator]);

	const selectedPact = watch('pactuado');

	const periodosPacts = useMemo(() => {
		if (selectedPact) {
			let periodos: any[] = [];
			let currentYear = selectedPact?.data?.periodos[0]?.ano!;

			selectedPact.data?.periodos.forEach(() => {
				const filtered = selectedPact.data?.periodos
					.filter(p => p.ano === currentYear)
					.map(periodo => ({
						...periodo,
						pesoCalculado: periodo.pesoCalculado <= 0 ? 'N/A' : (periodo.pesoCalculado * 100).toFixed(0),
					}));

				if (filtered?.length) {
					periodos = [
						...periodos,
						{
							ano: currentYear,
							metasPeriodos: filtered,
						},
					];
					currentYear = currentYear + 1;
				}
			});

			return periodos;
		}

		return [];
	}, [selectedPact]);

	const paramPact = useMemo(() => {
		if (selectedPact) {
			return selectedPact?.data?.periodos[1]?.idIndicadorPactoGestao;
		}

		return null;
	}, [selectedPact]);

	const { mutate: downloadMatrixMutate, isLoading: isLoadingMatrix } = useMutation<Blob, AxiosError<ResponseErrors>>(
		() => downloadMatrixIndicator({ indicadoresId: Number(indicatorId), indicadoresPactoGestaoId: paramPact }),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);
				downloadFile(href, 'matriz_indicadores.pdf');
				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
				onClose();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
			},
		},
	);

	const handleExport = () => {
		downloadMatrixMutate();
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} key={id}>
				<ModalOverlay />

				<ModalContent sx={styles.principal}>
					<ModalHeader sx={styles.header}>
						<SighMainLogoIconModal />

						<Box sx={styles.boxB}>
							<Text sx={styles.title}>Matriz</Text>
						</Box>
						<Box></Box>
						<ModalCloseButton size="2rem" sx={styles.modalCloseBtn} />
					</ModalHeader>
					<ModalBody>
						<>
							{isLoadingIndicator ? (
								<Box sx={styles?.loadingSpinner}>
									<Spinner size="xl" color="#3EA2A2" />
								</Box>
							) : (
								<>
									<Flex sx={styles.head}>
										<Tag variant="solid" sx={styles.headTag}>
											{
												TEXT_DATA_CONTROL_STEPS_ENUM[
													String(indicator?.indicador?.etapaEnum) as keyof typeof TEXT_DATA_CONTROL_STEPS_ENUM
												]
											}
										</Tag>
										<Heading sx={styles.headText}>{indicator?.indicador?.nome}</Heading>
									</Flex>
									{showPactuado && (
										<Box sx={styles.fasePactuadoContent}>
											<Controller
												control={control}
												name="pactuado"
												render={({ field: { onChange, value } }) => (
													<Select
														label="Pactuado"
														placeholder="Selecione o pactuado"
														isEditing={isEditing}
														value={value}
														options={parsedOptionArray(pactuados, 'pactuado', 'idPactuadoSigesp')}
														onChange={onChange}
														dataTestId="select--pactuado"
													/>
												)}
											/>
										</Box>
									)}
									<Grid sx={styles.gridContainer}>
										<GridItem sx={styles.gridContainerSecond}>
											<Flex sx={styles.modalBoxContainer}>
												<Text sx={styles.textBoxGrid}>Objetivo:</Text>
												<Text sx={styles.textBoxGrid}>{indicator?.indicador?.objetivo}</Text>
											</Flex>
										</GridItem>
										<GridItem>
											<Grid gap={'1rem'}>
												<GridItem sx={styles.gridContainerSecondTwo}>
													<Flex sx={styles.modalBoxContainer}>
														<Text sx={styles.textBoxGrid}>Objetivo estratégico:</Text>
														<Text sx={styles.textBoxGrid}>{indicator?.indicador?.objetivoEstrategico?.objetivo}</Text>
													</Flex>
												</GridItem>
												<GridItem sx={styles.gridContainerSecondTwo}>
													<Flex sx={styles.modalBoxContainer}>
														<Text sx={styles.textBoxGrid}>Perspectiva:</Text>
														<Text sx={styles.textBoxGrid}>{indicator?.indicador?.perspectiva?.perspectiva}</Text>
													</Flex>
												</GridItem>
											</Grid>
										</GridItem>
									</Grid>
									<Flex sx={styles.box}>
										<Box sx={styles.firstBox}>
											<Flex sx={styles.modalBoxContainer}>
												<Text sx={styles.textBoxGrid}>Metodologia de cálculo</Text>
												<Text sx={styles.textBoxGrid}>{indicator?.indicador?.metodologiaCalculo}</Text>
											</Flex>
										</Box>
										<Box sx={styles.firstBox}>
											<Flex sx={styles.modalBoxContainer}>
												<Text sx={styles.textBoxGrid}>Fontes de dados</Text>
												<Text sx={styles.textBoxGrid}>{indicator?.indicador?.fonteDados}</Text>
											</Flex>
										</Box>
										<Box sx={styles.firstBox}>
											<Flex sx={styles.modalBoxContainer}>
												<Text sx={styles.textBoxGrid}>Comprovação</Text>
												<Text sx={styles.textBoxGrid}>{indicator?.indicador?.fonteComprovacao}</Text>
											</Flex>
										</Box>
										<Grid sx={styles.gridContainerBottom}>
											<GridItem sx={styles.gridContainerFirst}>
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Lançamento</Text>
													<Text sx={styles.textBoxGrid2}>
														{
															RESPONSAVELLANCAMENTO[
																indicator?.indicador?.responsavelLancamento as keyof typeof RESPONSAVELLANCAMENTO
															]
														}
													</Text>
												</Flex>
												<Divider borderColor="white" />
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Área técnica</Text>
													<Text sx={styles.textBoxGrid2}>{indicator?.indicador?.areaTecnica?.nome}</Text>
												</Flex>
												<Divider borderColor="white" />
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Ano</Text>
													<Text sx={styles.textBoxGrid2}>{indicator?.indicador?.ano}</Text>
												</Flex>
												{indicator?.indicador?.sei && (
													<>
														<Divider borderColor="white" />
														<Flex sx={styles.modalFlexContainer}>
															<Text sx={styles.textBoxGrid}>S.E.I.</Text>
															<Text sx={styles.textBoxGrid2}>{indicator?.indicador?.sei}</Text>
														</Flex>
													</>
												)}
											</GridItem>
											<GridItem sx={styles.gridContainerFirst}>
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Unidade de medida</Text>
													<Text sx={styles.textBoxGrid2}>
														{UNIDADEMEDIDA[indicator?.indicador?.unidadeMedida as keyof typeof UNIDADEMEDIDA]}
													</Text>
												</Flex>
												<Divider borderColor="white" />
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Polaridade</Text>
													<Text sx={styles.textBoxGrid3}>
														{POLARITIES[indicator?.indicador?.polaridade as keyof typeof POLARITIES]}
														{POLARITY_STATUS[indicator?.indicador?.polaridade as keyof typeof POLARITY_STATUS]?.icon}
													</Text>
												</Flex>
												<Divider borderColor="white" />
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Meta cumulativa</Text>
													<Text sx={styles.textBoxGrid2}>
														{indicator?.indicador?.metaCumulativa === true ? 'Sim' : 'Não'}
													</Text>
												</Flex>
												<Divider borderColor="white" />
												<Flex sx={styles.modalFlexContainer}>
													<Text sx={styles.textBoxGrid}>Faixa de desempenho</Text>
													<Text sx={styles.textBoxGrid2}>{indicator?.indicador?.conjunto?.nome}</Text>
												</Flex>
											</GridItem>
										</Grid>
										<Flex flexDirection="column">
											<Text sx={styles.titleSecondBox}>Contato de referência técnica</Text>
											<Box sx={styles.secondBox}>
												<Flex sx={styles.modalSecBoxContainer}>
													<Text sx={styles.textBoxGrid2}>{indicator?.indicador?.setor.descricaoSetor}</Text>
													<Text sx={styles.textBoxGrid}> — {indicator?.indicador?.nomeResponsavel}</Text>
												</Flex>
												<Flex sx={styles.modalTerBoxContainer}>
													<Text sx={styles.textBoxGrid}>{indicator?.indicador?.telefone}</Text>
													<Text sx={styles.textBoxGrid}> {indicator?.indicador?.email}</Text>
												</Flex>
											</Box>
										</Flex>

										{!!selectedPact && (
											<Flex flexDirection="column">
												<Text sx={styles.titleThirdBox}>Metas {/* (*Prorrogado) */}</Text>
												<Box sx={styles.thirdBox}>
													<>
														{periodosPacts.map((item, index) => (
															<Box key={index} w="20rem">
																<Flex flexDirection={'column'}>
																	<Text sx={styles.textBoxGrid4}>{item.ano}</Text>

																	<Flex sx={styles.flexContainerTablePeriod}>
																		{item?.metasPeriodos?.map((metasPeriodo: any, index: number) => (
																			<Flex w={'100%'} key={index}>
																				<Text sx={styles.textBoxGrid4}>
																					{
																						BIMESTERS_ENUM[
																							metasPeriodo.periodoAtribuicaoPactuadoEnum as keyof typeof BIMESTERS_ENUM
																						]
																					}
																				</Text>

																				<Text sx={styles.textBoxGridPact}>{`Meta: ${metasPeriodo.meta}`}</Text>

																				<Text sx={styles.textBoxGridPact}>{`Peso: ${metasPeriodo.pesoCalculado}`}</Text>
																			</Flex>
																		))}
																	</Flex>
																</Flex>
															</Box>
														))}
													</>
												</Box>
											</Flex>
										)}
									</Flex>
								</>
							)}
						</>
					</ModalBody>

					<ModalFooter sx={styles.footerContent}>
						<Button
							leftIcon={<DownloadPdfIconModal />}
							sx={styles.button}
							onClick={handleExport}
							data-testid="download--bt-indicator"
							isLoading={isLoadingMatrix}
						>
							Baixar em PDF
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default IndicatorMatrix;
