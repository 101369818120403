/**
 *
 * ReportMonitoringDetailedModal
 *
 */
import ReportMonitoringDetailedTable, { ReportMonitoringDetailedTableData } from '../ReportMonitoringDetailedTable';
import Select from '../Select';
import { useMemo, useState } from 'react';
import React from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Tag,
	Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { DownloadPdfIconModal, SighMainLogoIconModal } from 'assets/icons';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import { IRequestDownloadAgreedPerformance, downloadDetailedReport } from 'services/http/monitoringReport';
import { IReportMonitoringResponse, Report } from 'types/reportMonitoring';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, BIMESTERS_OPTIONS, BIMESTER_TOOLTIP_INFO, STEPS_OPTIONS_FILTER } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { ResponseErrors } from 'utils/parseErrors';
import { verifyCell } from 'utils/verifyCell';

export interface ReportMonitoringDetailedData {
	etapa: string;
	unidade: string;
	numeroSetores: number;
	mediaDesempenho: number;
	setor: ReportMonitoringDetailedTableData[];
}

interface ReportMonitoringDetailedModalProps {
	onClose: () => void;
	isOpen: boolean;
	data: IReportMonitoringResponse;
	unitSelected: string;
}

const ReportMonitoringDetailedModal = ({ isOpen, onClose, data, unitSelected }: ReportMonitoringDetailedModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			minW: {
				base: '100%',
				xl: '72rem',
			},
			maxW: {
				base: '100%',
				xl: 'fit-content',
			},
			w: '100%',
			pl: '5.88rem',
			pr: '3.5rem',
			pt: '1.19rem',
			pb: '0.81rem',
		},
		header: {
			w: '100%',
			display: 'flex',
			flexDir: 'row',
			alignItems: 'center',
			p: 0,
		},
		headerContent: {
			w: '100%',
			mr: {
				base: '2.5rem',
				xl: '6.25rem',
			},
			justifyContent: 'center',
		},
		headerContentText: {
			fontSize: '1.5rem',
			fontWeight: 'medium',
		},
		headerCloseButton: {
			top: '1.55rem',
			right: '2rem',
			color: '#C6C6C6',
		},
		unitContainer: {
			mt: '1.75rem',
			alignItems: 'center',
			gap: '0.5rem',
			flexWrap: 'wrap',
		},
		sectorContainer: {
			gap: '3.438rem',
			mt: '1.5rem',
			flexWrap: 'wrap',
		},
		tag: {
			minW: '3.625rem',
			h: '1.25rem',
			fontSize: '0.85rem',
			borderRadius: '0.75rem',
			backgroundColor: '#3498DB',
		},
		title: {
			fontSize: '1.5rem',
			fontWeight: 'bold',
		},
		text: {
			fontSize: '3.063rem',
			fontWeight: 'bold',
			color: '#004A73',
		},
		inputContainer: {
			maxW: '11.75rem',
			mt: '1rem',
		},
		tableContainer: {
			gap: '2rem',
			mt: '1.25rem',
			flexDir: 'column',
		},
		modalFooter: {
			my: '2rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
			gap: '2.5rem',
			w: '100%',
			p: 0,
		},
		button: {
			px: '5rem',
			w: {
				base: '100%',
				xl: 'fit-content',
			},
		},
	};

	const [selectedBimester, setSelectedBimester] = useState<IOption>({
		label: '1° Bimestre',
		value: 'PRIMEIRO_BIMESTRE',
	});
	const { addToast } = useCustomToast();

	const { mutate: downloadAgreedPerformanceMutate } = useMutation<
		Blob,
		AxiosError<ResponseErrors>,
		IRequestDownloadAgreedPerformance
	>(data => downloadDetailedReport(data), {
		onSuccess: (data, requestData) => {
			const href = URL.createObjectURL(data);
			const fileType = requestData?.requestParam;
			downloadFile(href, `relatorio_detalhado.${fileType}`, fileType === 'pdf');
		},
		onError: ({ response }) => {
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			});
		},
	});

	const reportMonitoringDetailedData = useMemo(() => {
		const dataSelected = data?.relatorios?.filter(item => item.unidade === unitSelected);
		let totalSetores = 0;
		let totalMediaDesempenho = 0;
		let nomeUnidade = '';
		let unidadeId = 0;
		const addNumberSectors = (array: Report[]) => {
			array.forEach(obj => {
				totalSetores += obj.numeroSetores;
				totalMediaDesempenho += obj.mediaDesempenho;
				nomeUnidade = obj.unidade;
				unidadeId = obj.unidadeId;
			});
			const mediaDesempenho = totalMediaDesempenho / array.length;

			return { totalSetores, mediaDesempenho, nomeUnidade, unidadeId };
		};

		const filterByStageBimester = (array: Report[], bimestre: string) => {
			return array.map(obj => ({
				...obj,
				etapa: obj.etapa,
				unidade: obj.unidade,
				unidadeId: obj.unidadeId,
				setores: obj.setores.map(setor => ({
					...setor,
					etapa: obj.etapa,
					mediaPontos: setor.mediaPontos,
					nomeSetor: setor.nomeSetor,
					totalizacao: setor.totalizacao.filter(item => item.bimestre === bimestre),
					indicadores: setor?.indicadores?.map(indicador => ({
						...indicador,
						id: String(indicador?.id),
						metricas: indicador?.metricas?.filter(metrica => metrica?.bimestre === bimestre),
					})),
				})),
			}));
		};

		if (dataSelected && selectedBimester) {
			const averageTotalSectors = addNumberSectors(dataSelected);
			const filteredBimesterStage = filterByStageBimester(dataSelected, String(selectedBimester?.value));

			return {
				averageTotalSectors,
				filteredBimesterStage,
			};
		}
		return;
	}, [data?.relatorios, selectedBimester, unitSelected]);

	const handleDownloadFile = (fileType: string) => {
		if (reportMonitoringDetailedData)
			downloadAgreedPerformanceMutate({
				requestParam: fileType,
				requestBody: {
					...data,
					relatorios: reportMonitoringDetailedData?.filteredBimesterStage!,
				},
			});
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent sx={styles.container}>
				<ModalHeader sx={styles.header}>
					<SighMainLogoIconModal />

					<Flex sx={styles.headerContent}>
						<Text sx={styles.headerContentText}>Resultados Consolidados</Text>
					</Flex>

					<ModalCloseButton size="2rem" sx={styles.headerCloseButton} />
				</ModalHeader>

				<ModalBody p="0">
					{reportMonitoringDetailedData?.filteredBimesterStage.map((item, index) => (
						<React.Fragment key={index}>
							<Flex sx={styles.unitContainer}>
								<Tag variant="solid" sx={styles.tag} data-testid="text--etapa">
									{STEPS_OPTIONS_FILTER[item?.etapa as keyof typeof STEPS_OPTIONS_FILTER]}
								</Tag>
								<Text sx={styles.title} data-testid="text--unidade">
									Desempenho do pactuado: Unidade {reportMonitoringDetailedData?.averageTotalSectors?.nomeUnidade}
								</Text>
							</Flex>

							<Box sx={styles.sectorContainer} display={index > 0 ? 'none' : 'flex'}>
								<Box>
									<Text sx={styles.title}>Setores</Text>
									<Text sx={styles.text} data-testid="text--numero-setores">
										{reportMonitoringDetailedData?.averageTotalSectors?.totalSetores}
									</Text>
								</Box>
								<Box>
									<Text sx={styles.title}>Média de desempenho da unidade</Text>
									<Text sx={styles.text} data-testid="text--media-desempenho">
										{verifyCell(Number(reportMonitoringDetailedData?.averageTotalSectors?.mediaDesempenho))}
									</Text>
								</Box>
							</Box>

							<Box sx={styles.inputContainer} display={index > 0 ? 'none' : 'flex'}>
								<Select
									tooltipInfo={BIMESTER_TOOLTIP_INFO}
									label="Bimestre"
									placeholder="Selecione o bimestre"
									options={BIMESTERS_OPTIONS}
									value={selectedBimester}
									onChange={e => setSelectedBimester(e as IOption)}
									dataTestId="select--bimestre"
								/>
							</Box>

							<Flex sx={styles.tableContainer}>
								{item?.setores?.map((setor, index) => (
									<ReportMonitoringDetailedTable
										key={index}
										data={setor}
										selectedBimester={String(selectedBimester.value)}
									/>
								))}
							</Flex>
						</React.Fragment>
					))}

					<ModalFooter sx={styles.modalFooter}>
						<Button leftIcon={<DownloadPdfIconModal />} sx={styles.button} onClick={() => handleDownloadFile?.('pdf')}>
							Baixar em PDF
						</Button>
						<Button leftIcon={<DownloadPdfIconModal />} sx={styles.button} onClick={() => handleDownloadFile?.('xlsx')}>
							Baixar em XLSX
						</Button>
					</ModalFooter>
					<Text textAlign="center" onClick={onClose} cursor="pointer">
						Voltar
					</Text>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ReportMonitoringDetailedModal;
