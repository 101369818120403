import { getYear } from 'date-fns';
import { IOption } from 'types/select';

export const yearsArray = (size = 5) => {
	const currentYear = new Date().getFullYear();
	const endYear = currentYear + size;

	const years: IOption[] = [];

	for (let year = currentYear; year <= endYear; year++) {
		years.push({ label: year.toString(), value: year.toString() });
	}

	return years;
};

export const getYearsForRange = (yearsBefore = 5, yearsAfter = 5, currentYear = getYear(new Date())) => {
	const yearMin = currentYear - yearsBefore;
	const yearMax = currentYear + yearsAfter;

	return { yearMin, currentYear, yearMax };
};
