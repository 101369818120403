/**
 *
 * IndicatorsFilter
 *
 */

import CustomCalendar from '../CustomCalendar';
import InputText from '../InputText';
import Select from '../Select';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { Box, SystemStyleObject, Button, Grid } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getSector } from 'services/http/sectors';
import { getAllStrategicObjective } from 'services/http/strategicObjetive';
import { IPaginationParams } from 'types/pagination';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { IStrategicObjective } from 'types/strategicObjetive';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS_OPTIONS, FIRST_PAGE, SECTOR_TYPE } from 'utils/constants';
import { ResponseErrors, parseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import { getYearsForRange } from 'utils/yearsArray';
import * as yup from 'yup';

export interface IndicatorsFilterForm {
	nomeIndicador?: string;
	idObjetivo?: IOption;
	ano: string | number;
	etapaEnum?: IOption;
	nomePactuado?: IOption;
	areaTecnica?: IOption;
}

interface IIndicatorsFilterParams extends IPaginationParams, IndicatorsFilterForm {}

export type IndicatorsFilteKeys = keyof IIndicatorsFilterParams;

interface IndicatorsFilterProps {
	isListing?: boolean;
}

const IndicatorsFilter = ({ isListing = true }: IndicatorsFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		gridFields: {
			w: '100%',
			gap: {
				base: '2rem',
				lg: '3rem',
			},
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				xl: 'repeat(3, 1fr)',
			},
			alignItems: 'baseline',
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'column',
				xl: 'row',
			},
		},

		indicator: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '100%',
				xl: '32rem',
			},
		},
		gridItems: {
			width: '100%',
			maxWidth: {
				base: '100%',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '100', xl: '15rem' },
		},
	};

	const { addToast } = useCustomToast();
	const {
		session: { user },
	} = useSession();
	const [searchParams, setParams] = useSearchParams();

	const schema = yup.object().shape({
		nomeIndicador: yup.string(),
		ano: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		etapaEnum: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
		nomePactuado: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
		idObjetivo: yup.object().nullable().shape({
			label: yup.string(),
			value: yup.string(),
		}),
		areaTecnica: yup.object().nullable().shape({
			label: yup.string(),
			value: yup.string(),
		}),
	});

	// Seta os valores nos campos e desabilita o useEffect em seguida
	const [counter, setCounter] = useState(0);

	const { defaultValues, params } = useMemo(() => {
		const params = parseUrlParams<IndicatorsFilteKeys>(
			['nomeIndicador', 'idObjetivo', 'ano', 'etapaEnum', 'nomePactuado', 'areaTecnica', 'page', 'size'],
			searchParams,
		);

		const etapaEnum = DATA_CONTROL_STEPS_OPTIONS.find(item => item.value === params.etapaEnum);

		const defaultValues = {
			ano: params?.ano,
			nomeIndicador: params?.nomeIndicador,
			etapaEnum,
		};
		return { defaultValues, params };
	}, [searchParams]);

	const {
		control,
		handleSubmit,
		formState: { errors },
		register,
		setValue,
		watch,
		getValues,
		resetField,
	} = useForm<IndicatorsFilterForm>({
		resolver: !isListing ? undefined : yupResolver(schema),
		defaultValues,
	});

	const { yearMin: yearMinDate, yearMax: yearMaxDate } = getYearsForRange();

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION, SECTOR_TYPE.BOARD_TYPE],
	};

	const stepFieldData = getValues('etapaEnum.value') as string;
	const ano = watch('ano');

	const { data: strategicObjectives } = useQuery<
		IStrategicObjective[],
		AxiosError<ResponseErrors>,
		IStrategicObjective[]
	>(['strategic-objectives-indicators', ano], () => getAllStrategicObjective({ ano: String(ano) }), {
		onError: errors => addToast({ type: 'error', description: parseErrors(errors?.response?.data), title: 'Erro!' }),
		enabled: !!ano,
	});

	const { data: technicalArea, isLoading: isLoadingTechnicalArea } = useQuery<
		ISector[],
		AxiosError<ResponseErrors>,
		ISector[]
	>(
		['technical-area', stepFieldData],
		() => getSector(selectedStep[stepFieldData as keyof typeof selectedStep], stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!stepFieldData,
		},
	);

	const objectives: IOption[] = useMemo(() => {
		return (
			strategicObjectives?.map(item => ({
				value: item.id || '',
				label: item.objetivo || '',
			})) || []
		);
	}, [strategicObjectives]);

	useEffect(() => {
		if (counter < 3) {
			const idObjetivo = objectives?.find(item => item.value === Number(params.idObjetivo));
			const areaTecnica = parsedOptionArray(technicalArea, 'nome', 'idSetor')?.find(
				item => item.label === params.areaTecnica,
			);
			const pactuado = parsedOptionArray(technicalArea, 'nome', 'idSetor')?.find(
				item => item.label === params.nomePactuado,
			);

			setValue('idObjetivo', idObjetivo);
			setValue('areaTecnica', areaTecnica);
			setValue('nomePactuado', pactuado);
			setCounter(counter + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, objectives, technicalArea, setValue]);

	const clearFields = useCallback(
		(field: keyof IndicatorsFilterForm, defaultValue: string | IOption[] = '') => {
			resetField(field, { defaultValue });
		},
		[resetField],
	);

	useEffect(() => {
		clearFields('idObjetivo');
	}, [ano, clearFields]);

	const onSubmit = (values: IndicatorsFilterForm) => {
		const parseData = cleanDeep({
			nomeIndicador: values.nomeIndicador || '',
			ano: String(values.ano),
			etapaEnum: String(values.etapaEnum?.value || ''),
			nomePactuado: values.nomePactuado?.label || '',
			idObjetivo: String(values.idObjetivo?.value || ''),
			areaTecnica: values.areaTecnica?.label || '',
			page: String(FIRST_PAGE),
		});

		setParams(parseData);
	};

	return (
		<>
			<Box sx={styles.container}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<Grid sx={styles.gridFields}>
						<Box sx={styles.gridItems}>
							<InputText
								register={register}
								label="Nome do indicador"
								name="nomeIndicador"
								placeholder="Digite um nome de indicador"
								errorMessage={errors?.nomeIndicador?.message}
								data-testid="input--indicatorName"
								type="text"
							/>
						</Box>

						<Box sx={styles.gridItems}>
							<CustomCalendar
								label="Ano"
								placeholder="Selecione um ano"
								fieldName="ano"
								dataTestId="input--year"
								errorMessage={!ano ? errors.ano?.message : ''}
								setValue={setValue}
								minDate={new Date(yearMinDate, 0, 1)}
								maxDate={new Date(yearMaxDate, 0, 1)}
								control={control}
								required
							/>
						</Box>

						<Box sx={styles.gridItems}>
							<Controller
								name="etapaEnum"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Etapa"
											dataTestId="input--step"
											placeholder="Selecione a etapa"
											value={field?.value}
											onChange={e => {
												field?.onChange(e);
												clearFields('areaTecnica');
												clearFields('nomePactuado');
											}}
											options={DATA_CONTROL_STEPS_OPTIONS}
											errorMessage={errors?.etapaEnum?.value?.message}
										/>
									</>
								)}
							/>
						</Box>
					</Grid>

					<Box sx={styles.fields} marginTop={'1.5rem'}>
						<Box sx={styles.gridItems}>
							<Controller
								name="nomePactuado"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Pactuado"
											dataTestId="input--agreed-name"
											placeholder="Selecione um pactuado"
											value={field?.value}
											onChange={field?.onChange}
											options={parsedOptionArray(technicalArea, 'nome', 'idSetor')}
											placeholderSelectedItems={`${technicalArea?.values.name}`}
											errorMessage={errors?.nomePactuado?.message}
											isLoading={isLoadingTechnicalArea && !!getValues('etapaEnum.value')}
											isDisabled={!stepFieldData}
										/>
									</>
								)}
							/>
						</Box>

						<Box sx={styles.gridItems}>
							<Controller
								name="idObjetivo"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Objetivo estratégico"
											dataTestId="input--strategicObjective"
											placeholder="Selecione um objetivo"
											value={field?.value}
											onChange={field?.onChange}
											options={objectives}
											placeholderSelectedItems={`${objectives.values.name}`}
											errorMessage={errors?.idObjetivo?.value?.message}
											isDisabled={!ano}
										/>
									</>
								)}
							/>
						</Box>

						<Box sx={styles.gridItems}>
							<Controller
								name="areaTecnica"
								control={control}
								render={({ field }) => (
									<Select
										label="Área técnica"
										options={parsedOptionArray(technicalArea, 'nome', 'idSetor')}
										placeholder="Selecione a área técnica"
										dataTestId="input--areaTecnica"
										value={field?.value}
										onChange={value => {
											field?.onChange(value);
										}}
										errorMessage={errors?.areaTecnica?.message}
										placeholderSelectedItems={`${technicalArea?.values.name}`}
										isLoading={isLoadingTechnicalArea && !!getValues('etapaEnum.value')}
										isDisabled={!stepFieldData}
									/>
								)}
							/>
						</Box>
					</Box>
					<Box sx={styles.buttonSubmit}>
						<Button type="submit" sx={styles.button} data-testid="button--submit">
							Pesquisar
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default IndicatorsFilter;
