/**
 *
 * ReportMonitoringDetailedTable
 *
 */

import { useMemo } from 'react';
import { Box, SystemStyleObject, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { IIndicatorReport, ReportSectorTotalization } from 'types/reportMonitoring';
import { IRow } from 'types/table';
import { PERCENTAGE_SYMBOL, POLARITIES, UNIDADEMEDIDA } from 'utils/constants';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { verifyCell } from 'utils/verifyCell';

export interface ReportMonitoringDetailedTableData {
	etapa?: string;
	nomeSetor: string;
	mediaPontos: number;
	totalizacao: ReportSectorTotalization[];
	totalPeso?: number;
	totalPontuacao?: number;
	indicadores: IIndicatorReport[];
}

export interface ReportMonitoringDetailedTableProps {
	data: ReportMonitoringDetailedTableData;
	selectedBimester: string;
}

const ReportMonitoringDetailedTable = ({ data, selectedBimester }: ReportMonitoringDetailedTableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			w: '100%',
			backgroundColor: '#0a77d21a',
			borderRadius: '0.25rem',
			overflowX: 'auto',

			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		content: {
			minW: '62.5rem',
		},
		tooltip: {
			color: '#3B3333',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			top: '10px',
			minW: '100%',
			maxW: '100%',
		},
		footerText: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		tableFooterLastColumn: { textAlign: 'start !important' },
		percentageData: {
			w: '100%',
			color: '#2A89D8',
			fontWeight: 'bold',
		},
		commonData: {
			w: '100%',
			color: '#000000de',
			fontWeight: 'medium',
		},
	};
	const columns = [
		{
			label: 'Nome do indicador',
			key: 'indicador',
		},
		{
			label: 'Polaridade',
			key: 'polaridade',
		},
		{
			label: 'Meta',
			key: 'meta',
		},
		{
			label: 'Resultado',
			key: 'resultado',
		},
		{
			label: 'Desempenho',
			key: 'desempenho',
		},
		{
			label: 'Peso',
			key: 'peso',
		},
		{
			label: 'Pontuação',
			key: 'pontuacao',
		},
	];

	const isPercentageField = (data: string) => data.includes(PERCENTAGE_SYMBOL);

	const tableData = useMemo(
		() =>
			data.indicadores.map(indicador => {
				const bimesterData = indicador.metricas.find(item => item.bimestre === selectedBimester)!;

				const meta = verifyCell(bimesterData?.meta, indicador?.unidadeMedida);
				const resultado = verifyCell(bimesterData?.resultado, indicador?.unidadeMedida);
				const desempenho = verifyCell(bimesterData?.desempenho, UNIDADEMEDIDA?.PERCENTUAL);
				const peso = verifyCell(bimesterData?.pesoCalculado * 100);
				const pontuacao = verifyCell(bimesterData?.pontuacao);

				return {
					indicador: indicador.nome,
					polaridade: POLARITIES[indicador.polaridade as keyof typeof POLARITIES],
					meta,
					resultado,
					desempenho,
					peso,
					pontuacao,
				};
			}) as IRow[],
		[data.indicadores, selectedBimester],
	);

	const { desempenho, penalizacao } = useMemo(() => {
		const totalizacao = data?.totalizacao?.find(value => value?.bimestre === selectedBimester);

		if (totalizacao)
			return {
				totalPontuacao: totalizacao?.totalPontuacao < 0 ? 'N/A' : formatDecimalNumber(totalizacao?.totalPontuacao),
				penalizacao: `${totalizacao?.notaPenalizacao}`.replace('.', ','),
				desempenho: verifyCell(Number(totalizacao?.desempenho)),
			};

		return { totalPontuacao: '', penalizacao: '' };
	}, [data, selectedBimester]);

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<Table variant="reportMonitoringModal">
					<Thead>
						<Tr>
							<Th colSpan={7} data-testid="text--nome-setor">
								{data.nomeSetor}
							</Th>
						</Tr>
						<Tr>
							{columns.map(col => (
								<Th key={col.key}>{col.label}</Th>
							))}
						</Tr>
					</Thead>

					<Tbody>
						{tableData.map((row, index) => (
							<Tr key={index}>
								{columns.map(col => (
									<Td key={`${index}-${col?.key}`}>
										<Text
											fontSize="1rem"
											sx={isPercentageField(row[col.key] as string) ? styles.percentageData : styles.commonData}
											data-testid={`text--${index}-${col?.key}`}
										>
											{row[col.key] as string}
										</Text>
									</Td>
								))}
							</Tr>
						))}
					</Tbody>
					<Tfoot>
						<Tr>
							<Th colSpan={6}>
								<Tooltip
									placement="top-start"
									hasArrow
									arrowSize={7}
									bg="white"
									sx={styles.tooltip}
									label="Setores sem indicadores atribuídos herdam a nota do setor superior ao qual estão vinculados na hierarquia."
								>
									TOTAL GERAL
								</Tooltip>
							</Th>
							<Th colSpan={1} sx={styles.tableFooterLastColumn}>
								<Tooltip
									placement="top-start"
									hasArrow
									arrowSize={7}
									bg="white"
									sx={styles.tooltip}
									label="Resultado total com pontos subtraídos."
								>
									<Text as="span" sx={styles.footerText} data-testid="text--resultado">
										{desempenho}
									</Text>
								</Tooltip>{' '}
								<Tooltip
									placement="top-start"
									hasArrow
									arrowSize={7}
									bg="white"
									sx={styles.tooltip}
									label="Total de pontos subtraídos"
								>
									<Text as="span" sx={{ ...styles.footerText, color: '#CC3300' }} data-testid="text--pontos-perdidos">
										({penalizacao}*)
									</Text>
								</Tooltip>
							</Th>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
		</Box>
	);
};

export default ReportMonitoringDetailedTable;
