export const ENDPOINTS = {
	login: `/usuario/logado`,
	refreshToken: '/users/get-access-token',
	userData: '/dados-usuario',
	userMail: '/usuario',

	// Periods
	periods: '/planejamento/periodos',
	periodsConcats: '/pacto-gestao/periodos-completos',
	periodsBimester: '/periodo-pactuado',

	// Perspectives
	getPerspective: '/perspectiva/concat',
	getPerspectiveByYear: '/perspectiva/anos',

	// StrategicPlanning
	strategicPlanningList: '/planejamento',
	strategicPlanningCreate: '/planejamento',
	strategicPlanningGetById: (id: string) => `/planejamento/${id}`,
	strategicPlanningUpdate: (id: string) => `/planejamento/${id}`,
	strategicPlanningDelete: (strategicPlanningId: string) => `/planejamento/${strategicPlanningId}`,

	// StrategicObjective
	strategicObjectivesList: '/objetivo',
	strategicObjectivesListAll: '/objetivo/todos',
	strategicObjectiveCreate: '/objetivo',
	strategicObjectiveGetById: (objectiveId: string) => `/objetivo/${objectiveId}`,
	strategicObjectiveUpdate: (objectiveId: string) => `/objetivo/${objectiveId}`,
	strategicObjectiveDelete: (objectiveId: string) => `/objetivo/${objectiveId}`,

	// Indicators
	indicatorsList: '/indicadores',
	indicatorsPactLinkList: '/indicadores/vinculo-pacto',
	indicatorsNameList: '/indicadores/findIndicadoresByPactuado',
	indicatorsDelete: '/indicadores',
	indicatorsUpdateWeight: '/indicadores/update-peso',
	indicatorsCreate: '/indicadores',
	indicatorsUpdate: '/indicadores',
	indicatorsGetById: (indicatorId: string) => `/indicadores/${indicatorId}`,
	indicatorsValidate: (indicatorId: string) => `/indicadores/${indicatorId}`,
	indicatorDownloadMatrix: '/indicadores/report/matriz/download',
	indicatorsDuplicity: '/indicadores/validar-duplicidade',
	indicatorsMatriz: (indicatorId: string) => `/indicadores/matriz/${indicatorId}`,

	//PerformanceRange
	performanceRangeList: '/conjuntos',
	performanceRangeCreate: '/conjuntos',
	performanceRangeListName: '/conjuntos',
	performanceRangeGetById: (performanceRangeId: string) => `/conjuntos/${performanceRangeId}`,
	performanceRangeUpdate: (performanceRangeId: string) => `/conjuntos/${performanceRangeId}`,
	performanceRangeUpdateStatus: (performanceRangeId: string) => `/conjuntos/${performanceRangeId}/inativacao`,
	performanceRangeDelete: (performanceRangeId: string) => `/conjuntos/${performanceRangeId}`,

	// ManagementPact
	lastActivePact: '/pacto-gestao/find-ano-bimestre',
	lastActivePactCreate: '/pacto-gestao/find-ano-bimestre-cadastro',
	managementPactCreate: '/pacto-gestao',
	managementPactList: '/pacto-gestao/findByPactuadoByAno',
	managementPactPeriods: '/pacto-gestao/periodos',
	managementPactGetById: (pactId: string) => `/pacto-gestao/${pactId}`,
	managementPactUpdate: (pactId: string) => `/pacto-gestao/${pactId}`,
	managementPactDelete: (pactId: string) => `/pacto-gestao/${pactId}`,
	managementPactValidate: (pactId: string) => `/pacto-gestao/${pactId}`,

	// DateControl
	dateControlList: '/controle-datas',
	dateControlCreate: '/controle-datas',
	dateControlGetById: (dateControlId: string) => `/controle-datas/${dateControlId}`,
	dateControlUpdate: (dateControlId: string) => `/controle-datas/${dateControlId}`,
	dateControlDelete: (dateControlId: string) => `/controle-datas/${dateControlId}`,
	dateControlExportTableData: '/controle-datas/exportacao-datas',
	dateControlExportPdfData: '/controle-datas/exportacao-datas/geracao-relatorio',
	dateControlPeriods: '/pacto-gestao/periodos',
	dateControlListSteps: '/controle-datas/busca-por-etapa',
	dateControlCheckOverlap: '/controle-datas/verifica-sobreposicao-datas',

	// Penalties
	penaltiesList: '/penalizacoes/filter',
	penaltyCreate: '/penalizacoes',
	penaltyDelete: '/penalizacoes',
	penaltyUpdate: (penaltyId: string) => `/penalizacoes/${penaltyId}`,
	penaltyGetById: (penaltyId: string) => `/penalizacoes/${penaltyId}`,
	penaltyValidateId: (penaltyId: string) => `/penalizacoes/valida-update-delete/${penaltyId}`,
	penaltyPeriods: '/pacto-gestao/periodos-completos',

	// Agreeds
	agreeds: '/pactuados',

	// Sectors
	sectors: 'setores',
	boardSectors: (boardId: string) => `setores/diretoria/${boardId}`,

	// Resultados
	resultsList: '/resultados/findAll',
	resultsListConverted: '/resultados/converte-tela-resultados',
	allResultsListConverted: '/resultados/find-all/converte-tela-resultados',

	// Pactos Ativos
	activePacts: '/atribuicao-pactuado',

	// CAAP Members
	caapMembersList: '/caap',
	caapMembersListAll: '/caap/list',
	caapMembersDelete: (id: string) => `/caap/${id}`,
	caapCreate: '/caap',
	caapFunctions: '/caap/funcoes',
	caapMembers: '/membros-caap',
	caapMembersFilter: '/membros-caap/filter',
	caapGetById: (id: string) => `/caap/${id}`,
	caapEdit: (id: string) => `/caap/${id}`,
	caappMembersExport: '/caap/export-composicao',

	//Result Evaluation
	resultEvaluationSummary: (id: string) => `/avaliacao-resultado/resumo/${id}`,
	resultEvaluationHistory: (id: string) => `/avaliacao-resultado/lista-de-avaliacoes/${id}/historico`,
	resultEvaluationList: (id: string) => `/avaliacao-resultado/lista-de-avaliacoes/${id}`,
	resultEvaluationCalc: '/avaliacao-resultado/calculos',
	resultEvaluationGraphic: (id: string) => `/avaliacao-resultado/grafico/${id}`,
	resultEvaluationDownloadFile: '/files/downloads/',

	resultEvaluationUploadContinuousImprovement: (id: string) =>
		`/avaliacao-resultado/lista-de-avaliacoes/${id}/melhoria-continua/documentos/upload`,
	resultEvaluationListContinuousImprovement: (id: string) =>
		`/avaliacao-resultado/lista-de-avaliacoes/${id}/melhoria-continua/documentos`,
	resultEvaluationDeleteContinuousImprovement: (id: string) =>
		`/avaliacao-resultado/lista-de-avaliacoes/melhoria-continua/documentos/${id}`,

	resultEvaluationListContinuousImprovementRequest: (id: string) =>
		`/avaliacao-resultado/lista-de-avaliacoes/${id}/melhoria-continua/solicitacao`,
	resultEvaluationCreateContinuousImprovementRequest: (id: string) =>
		`/avaliacao-resultado/lista-de-avaliacoes/${id}/melhoria-continua/solicitacao`,
	resultEvaluationUpdateContinuousImprovementRequest: (id: string) =>
		`/avaliacao-resultado/lista-de-avaliacoes/${id}/melhoria-continua/solicitacao`,

	resultEvaluationUploadLaunchPhase: '/avaliacao-resultado/fase/lancamento/arquivos/upload',
	resultEvaluationCreateLaunchPhase: '/avaliacao-resultado/fase/lancamento',
	resultEvaluationUpdateLaunchPhase: (id: string) => `/avaliacao-resultado/fase/lancamento/${id}`,
	resultEvaluationDeleteLaunchPhase: (id: string) => `/avaliacao-resultado/fase/lancamento/${id}`,
	resultEvaluationLaunchPhaseNext: '/avaliacao-resultado/fase/lancamento/proxima-fase',
	resultEvaluationLaunchPhaseExport: '/avaliacao-resultado/fase/lancamento/exportarPdf',

	resultEvaluationUploadValidationPhase: '/avaliacao-resultado/fase/validacao/arquivos/upload',
	resultEvaluationCreateValidationPhase: '/avaliacao-resultado/fase/validacao',
	resultEvaluationUpdateValidationPhase: (id: string) => `/avaliacao-resultado/fase/validacao/${id}`,
	resultEvaluationDeleteValidationPhase: (id: string) => `/avaliacao-resultado/fase/validacao/${id}`,
	resultEvaluationValidationPhaseNext: '/avaliacao-resultado/fase/validacao/proxima-fase',
	resultEvaluationValidationPhaseExport: '/avaliacao-resultado/fase/validacao/exportarPdf',

	resultEvaluationUploadAppealFirstInstancePhase:
		'/avaliacao-resultado/fase/recorrer-primeira-instancia/arquivos/upload',
	resultEvaluationCreateAppealFirstInstancePhase: '/avaliacao-resultado/fase/recorrer-primeira-instancia',
	resultEvaluationUpdateAppealFirstInstancePhase: (id: string) =>
		`/avaliacao-resultado/fase/recorrer-primeira-instancia/${id}`,
	resultEvaluationDeleteAppealFirstInstancePhase: (id: string) =>
		`/avaliacao-resultado/fase/recorrer-primeira-instancia/${id}`,
	resultEvaluationAppealFirstInstancePhaseNext: '/avaliacao-resultado/fase/recorrer-primeira-instancia/proxima-fase',
	resultEvaluationAppealFirstInstancePhaseExport: '/avaliacao-resultado/fase/recorrer-primeira-instancia/exportarPdf',

	resultEvaluationUploadDecisionFirstInstancePhase:
		'/avaliacao-resultado/fase/decisao-primeira-instancia/arquivos/upload',
	resultEvaluationCreateDecisionFirstInstancePhase: '/avaliacao-resultado/fase/decisao-primeira-instancia',
	resultEvaluationUpdateDecisionFirstInstancePhase: (id: string) =>
		`/avaliacao-resultado/fase/decisao-primeira-instancia/${id}`,
	resultEvaluationDeleteDecisionFirstInstancePhase: (id: string) =>
		`/avaliacao-resultado/fase/decisao-primeira-instancia/${id}`,
	resultEvaluationDecisionFirstInstancePhaseNext: '/avaliacao-resultado/fase/decisao-primeira-instancia/proxima-fase',
	resultEvaluationDecisionFirstInstancePhaseExport: '/avaliacao-resultado/fase/decisao-primeira-instancia/exportarPdf',

	resultEvaluationUploadAppealSecondInstancePhase:
		'/avaliacao-resultado/fase/recorrer-segunda-instancia/arquivos/upload',
	resultEvaluationCreateAppealSecondInstancePhase: '/avaliacao-resultado/fase/recorrer-segunda-instancia',
	resultEvaluationUpdateAppealSecondInstancePhase: (id: string) =>
		`/avaliacao-resultado/fase/recorrer-segunda-instancia/${id}`,
	resultEvaluationDeleteAppealSecondInstancePhase: (id: string) =>
		`/avaliacao-resultado/fase/recorrer-segunda-instancia/${id}`,
	resultEvaluationAppealSecondInstancePhaseNext: '/avaliacao-resultado/fase/recorrer-segunda-instancia/proxima-fase',
	resultEvaluationAppealSecondInstancePhaseExport: '/avaliacao-resultado/fase/recorrer-segunda-instancia/exportarPdf',

	resultEvaluationUploadCounterReasonsPhase: '/avaliacao-resultado/fase/contrarrazoes/arquivos/upload',
	resultEvaluationCreateCounterReasonsPhase: '/avaliacao-resultado/fase/contrarrazoes',
	resultEvaluationUpdateCounterReasonsPhase: (id: string) => `/avaliacao-resultado/fase/contrarrazoes/${id}`,
	resultEvaluationDeleteCounterReasonsPhase: (id: string) => `/avaliacao-resultado/fase/contrarrazoes/${id}`,
	resultEvaluationCounterReasonsPhaseNext: '/avaliacao-resultado/fase/contrarrazoes/proxima-fase',
	resultEvaluationCounterReasonsPhaseExport: '/avaliacao-resultado/fase/contrarrazoes/exportarPdf',

	resultEvaluationUploadCaapDecisionPhase: '/avaliacao-resultado/fase/decisao-caap/arquivos/upload',
	resultEvaluationCreateCaapDecisionPhase: '/avaliacao-resultado/fase/decisao-caap',
	resultEvaluationUpdateCaapDecisionPhase: (id: string) => `/avaliacao-resultado/fase/decisao-caap/${id}`,
	resultEvaluationDeleteCaapDecisionPhase: (id: string) => `/avaliacao-resultado/fase/decisao-caap/${id}`,
	resultEvaluationCaapDecisionPhaseExport: '/avaliacao-resultado/fase/decisao-caap/exportarPdf',
	resultEvaluationCaapDecisionPhaseExportVote: '/avaliacao-resultado/fase/decisao-caap/exportarPdfVotacao',

	// RECORD AUDIT
	getAuditModules: '/registros-auditoria/modulos',
	getAuditComponents: '/registros-auditoria/componentes',
	getAuditRecords: '/registros-auditoria',

	//REPORT MONITORING
	getReportMonitoring: '/relatorio-geral-unidades',
	downloadReducedReportXlsx: '/relatorio-geral-unidades/export/reduzido/xlsx',
	downloadReducedReportPdf: '/relatorio-geral-unidades/export/reduzido/pdf',
	downloadCompleteReportXlsx: '/relatorio-geral-unidades/export/completo/xlsx',
	downloadCompleteReportPdf: '/relatorio-geral-unidades/export/completo/pdf',
	downloadDetailedReport: (requestParam: string) => `relatorio-geral-unidades/export/detalhado/${requestParam}`,

	// NOTIFICATIONS
	getAllNotifications: '/notificacoes/todas',
	getRecentsNotifications: '/notificacoes/recentes',
	updateMarkedNotifications: '/notificacoes/marcar',
	updateAllNotifications: '/notificacoes/marcar-como-lido/todos',
	deleteNotifications: '/notificacoes/apagar',

	// REPRESENTATIVE
	representativeList: '/representante',
	representativesCaapsMemberList: '/membros-caap/representates',
	representativeGetBy: (id: string) => `/representante/${id}`,
	representativeEdit: (id: string) => `/representante/${id}`,
	representativeCreate: '/representante',

	// CONTROL PANEL
	controlPanelGraphicResults: '/painel-controle/resultados-grafico',
	controlPanelIndicators: '/painel-controle/indicadores',
	controlPanelIndicatorsResults: '/painel-controle/resultados',
	controlPanelDataControl: '/painel-controle/controle-datas',
	controlPanelDeadlineIndicators: '/painel-controle/prazos/indicadores',
	controlPanelDeadlineModal: '/painel-controle/prazos/modal',
	controlPanelDeadlinePhases: '/painel-controle/prazos/fases-lancamento',
	controlPanelCurrentPeriod: '/painel-controle/busca-periodo-vigente',
};
