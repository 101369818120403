/**
 *
 * PactForm
 *
 */

import GoalGroup from './GoalGroup';
import CustomCalendar from '../CustomCalendar';
import CustomModal from '../CustomModal';
import IndicatorMatrix from '../IndicatorMatrix';
import IndicatorsFormValidationHover from '../IndicatorsFormValidationHover';
import InputText from '../InputText';
import Prompt from '../Prompt';
import Select from '../Select';
import { useEffect, useMemo, useState } from 'react';
import { SmallAddIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Divider,
	Flex,
	HStack,
	IconButton,
	SystemStyleObject,
	Tooltip,
	Text,
	useDisclosure,
	Stack,
	Spinner,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IndicatorPactDelete, ViewMatrixIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { getYear } from 'date-fns';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getIndicatorsPactLinkList } from 'services/http/indicators';
import { IResponseLastActivePact, LastActivePactParams, getLastActivePact } from 'services/http/managementPact';
import { getSector } from 'services/http/sectors';
import { IPeriod, IPeriodYear } from 'types/agreeds';
import { Indicator } from 'types/indicators';
import { Pacts } from 'types/managementPact';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS, SECTOR_TYPE, STEPS_ENUM, USER_ROLES } from 'utils/constants';
import { TEXT_TO_BIMESTERS_ENUM } from 'utils/constants';
import { VALUE_TO_BIMESTERS_ENUM } from 'utils/constants';
import {
	LastActivePact,
	formatFromStringToNumber,
	getBimester,
	getLastBimester,
	getNextSixBimesters,
} from 'utils/formatBimesters';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import { getYearsForRange } from 'utils/yearsArray';
import * as yup from 'yup';

export interface IPactForm {
	pactuado?: IOption<ISector>;
	copyValues?: string[];
	anoInicial?: number;
	anoFinal?: number;
	indicadores?: Pacts[];
	etapa?: string;
}

interface PactFormProps {
	defaultValues?: IPactForm;
	indicadoresEdit?: Indicator[];
	isIndicadoresEditLoading?: boolean;
	onSubmitForm: (values: IPactForm) => void;
	isReadOnly?: boolean;
	isEditing?: boolean;
	isSubmitLoading?: boolean;
	validationDate?: string | null;
	onHandleValidate?: (values: IPactForm, changed: boolean) => void;
}

type Params = {
	etapa: keyof typeof DATA_CONTROL_STEPS;
};

interface SelectedToDelete extends Pacts {
	index: number;
}

const PactForm = ({
	isReadOnly = false,
	isEditing = false,
	isSubmitLoading = false,
	isIndicadoresEditLoading = false,
	indicadoresEdit,
	defaultValues,
	onSubmitForm,
	validationDate,
	onHandleValidate,
}: PactFormProps) => {
	const schema = yup.object().shape({
		pactuado: yup.object().required('Este campo é obrigatório.'),
		anoInicial: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		anoFinal: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		indicadores: yup.array().of(
			yup.object().shape({
				indicador: yup.object().required('Este campo é obrigatório.'),
				periodos: yup.array().of(
					yup.object().shape({
						periodos: yup.array().of(
							yup.object().shape({
								meta: yup
									.mixed()
									.required('Campo meta é obrigatório')
									.test('noValue', 'Campo meta é obrigatório.', (value = '') => {
										const maxValue = '';
										const isInvalid = maxValue === value;
										return !isInvalid;
									})
									.test('belowMinValue', 'Campo meta inválido.', value => {
										const minValue = 0.1;
										const isInvalid = formatFromStringToNumber(value) < minValue;
										return !isInvalid;
									}),
								peso: yup
									.mixed()
									.required('Campo peso é obrigatório')
									.test('noValuePeso', 'Campo peso é obrigatório.', (value = '') => {
										const maxValue = '';
										const isInvalid = maxValue === value;
										return !isInvalid;
									})
									.test('maxValue', 'Limite máximo para peso é 9.9.', value => {
										const maxValue = 9.9;
										const isInvalid = maxValue < formatFromStringToNumber(value);
										return !isInvalid;
									}),
							}),
						),
					}),
				),
			}),
		),
	});

	const { etapa } = useParams<Params>();
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const isValidBimester = (isEditavel: boolean, isRetroativo: boolean, peso: number) => {
		if (isRetroativo) return false;

		if (isEditavel === false && peso === -1) return true;

		if (!isEditavel) return false;

		return true;
	};

	const formattedValues = useMemo(() => {
		return defaultValues?.indicadores?.length
			? defaultValues.indicadores.map(
					indicador =>
						({
							...indicador,
							request: 'PUT',
							periodos: indicador.periodos?.map(periodo => {
								periodo.periodos?.map(
									bimester =>
										(bimester.isEditavel = isValidBimester(
											bimester.isEditavel!,
											bimester.isRetroativo!,
											bimester.pesoCalculado,
										)),
								);
								return periodo;
							}),
						} as Pacts),
			  )
			: [
					{
						indicador: undefined,
						periodos: undefined,
						request: 'POST',
					} as Pacts,
			  ];
	}, [defaultValues]);

	const {
		handleSubmit,
		register,
		control,
		setValue,
		getValues,
		resetField,
		watch,
		formState: { errors, isDirty, dirtyFields, isSubmitting, isSubmitted },
	} = useForm<IPactForm>({
		resolver: yupResolver(schema),
		defaultValues: { ...defaultValues, indicadores: formattedValues },
	});
	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { fields, append, remove, update, replace } = useFieldArray({
		name: 'indicadores',
		keyName: 'code',
		control,
	});

	const styles: Record<string, SystemStyleObject> = {
		container: {
			bgColor: 'gray.80',
			border: '0.063rem solid',
			borderColor: 'gray.95',
			borderRadius: '0.25rem',
			mb: '1rem',
			padding: '2rem',

			_first: {
				borderBottom: fields.length ? 'none' : '0.063rem solid',
				borderColor: fields.length ? 'none' : 'gray.95',
				borderBottomStartRadius: fields.length ? '0' : '0.25rem',
				borderBottomEndRadius: fields.length ? '0' : '0.25rem',
				paddingBottom: '0',
				mb: '0',
			},

			':nth-of-type(2)': {
				borderTop: 'none',
				borderTopStartRadius: '0',
				borderTopEndRadius: '0',
				paddingTop: '0',

				borderBottom: '0.063rem solid',
				borderColor: 'gray.95',
				borderBottomStartRadius: '0.25rem',
				borderBottomEndRadius: '0.25rem',
				paddingBottom: '2rem',
				mb: '1rem',
			},
		},
		stack: {
			paddingTop: '1.5rem',
			flexDirection: { base: 'column', lg: 'row' },
			alignItems: { base: ' flex-start', lg: 'center' },
			position: 'relative',
			flexWrap: 'wrap',
			gap: '1rem',
		},
		title: {
			color: 'black.800',
			fontSize: '1.25rem',
			fontWeight: '600',
			marginBottom: { base: '1.563rem', lg: 'auto' },
			marginRight: '1rem',
		},
		yearTitle: {
			color: 'black.800',
			fontSize: '1.25rem',
			fontWeight: 'bold',
			paddingBottom: '1.5rem',
			paddingTop: '1.5rem',
		},
		checkboxInput: {
			fontSize: '1rem',
			fontFamily: 'Lato',
			fontWeight: '600',
			color: 'black.800',
			"span[class*='checkbox__control']:not([data-disabled])": {
				borderColor: 'gray.95',
				_checked: {
					bg: 'blue.400',
					borderColor: 'blue.400',
					color: 'white',
				},
			},
			"span[class*='checkbox__control']:is([data-disabled])": {
				borderColor: 'gray.95',
				_disabled: {
					bg: 'blue.100',
					borderColor: 'blue.100',
					color: 'white',
				},
			},
			"span[class*='chakra-checkbox__label']:is([data-disabled])": {
				opacity: '1',
			},
			"span[class*='checkbox__control']:is([data-disabled]):[aria-disabled=true]": {
				borderColor: 'gray.95',
				_disabled: {
					bg: 'blue.100',
					borderColor: 'blue.100',
					color: 'white',
				},
			},
		},
		buttonApply: {
			borderRadius: '1rem',
			height: '1.688rem',
			width: '4.25rem',
			fontFamily: 'Lato',
			fontSize: '0.75rem',
		},
		gridFields: {
			w: { base: '100%', lg: '60rem' },
			gap: '3.75rem',
			rowGap: '2rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
			maxWidth: { base: '18.75rem', md: '40.625rem', lg: '56.25rem' },
		},
		inputContainer: {
			h: '2.75rem',
			w: '24.5rem',
			backgroundColor: 'white',
			border: '1px solid #E1E2E5',
			borderRadius: '1.375rem',
			p: '0.25rem 1rem',
			display: 'flex',
			alignItems: 'center',
		},
		periodText: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		inputLabel: {
			fontSize: '1rem',
			fontWeight: 'medium',
		},
		goalContainer: {
			borderRight: '1px solid #E1E2E5',
			borderLeft: '1px solid #E1E2E5',
			alignItems: 'center',
			pl: '1rem',
		},
		goalInput: {
			border: 'none',
			w: '6rem',
			h: '2.25rem',
			fontSize: '1rem',
			p: '0',
			pl: '0.25rem',
		},
		weightContainer: {
			alignItems: 'center',
			pl: '1rem',
		},
		weightInput: {
			border: 'none',
			w: '2.25rem',
			h: '2.25rem',
			fontSize: '1rem',
			p: '0',
			pl: '0.25rem',
		},
		actions: {
			mt: '3.125rem',
			textAlign: 'right',
			fontSize: '1rem',
		},
		actionsButtons: {
			mt: { base: '0', lg: '1rem' },
			display: 'flex',
			justifyContent: 'start',
			gap: {
				base: '1rem',
				sm: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'row',
			},
		},
		button: {
			w: { base: '100%', lg: '14.625rem' },
			fontSize: { base: '1rem' },
			h: { base: '2.75rem' },
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
			h: '50vh',
		},
		pact: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '35.125rem',
			},
		},
		pactSelected: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '24.5rem',
			},
			mr: { base: 'auto', lg: '8.75rem' },
		},
		years: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '7.625rem',
			},
		},
		fields: {
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2.7rem',
				md: '2.7rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
			paddingBottom: '2rem',
		},
		checkbox: {
			alignSelf: 'end',
			pb: '0.5rem',
		},
		buttonContainer: {
			width: '100%',
			justifyContent: 'center',
			display: 'flex',
		},
		buttonAdd: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '23.063rem' },
		},
		boxBtnRmv: {
			display: 'flex',
			flexDirection: 'row-reverse',
			position: 'absolute',
			top: '1rem',
			right: '0',
		},
		buttonRemove: {
			display: 'flex',
			fontSize: '0.875rem',
			color: 'red.450',
			height: 'auto',
			paddingLeft: '0.313rem',
			alignSelf: { base: 'flex-start', md: 'center' },
		},
		matrixContent: {
			position: { base: 'absolute', lg: 'relative' },
			alignItems: { base: 'center', lg: 'baseline' },
			paddingInlineStart: { base: '9rem', lg: '0' },
			paddingTop: { base: '0.8rem', lg: '0' },
			width: '1rem',
		},
		tooltipBtn: {
			marginTop: { base: '0', lg: '2.188rem' },
			position: { base: 'absolute', lg: 'relative' },
		},
		checkboxContainer: {
			mt: '0.125rem',
			gap: '1rem',
			flexWrap: 'wrap',
		},
	};

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
	const { isOpen: isOpenExportModal, onOpen: onOpenExportModal, onClose: onCloseExportModal } = useDisclosure();
	const navigate = useNavigate();
	const [lastActivePact, setLastActivePact] = useState<LastActivePact>({} as LastActivePact);
	const [pactData, setPactData] = useState<LastActivePact>(lastActivePact);
	const [pactYearsToDisable, setPactYearsToDisable] = useState<number[]>([]);
	const [indicatorsDeleted, setIndicatorsDeleted] = useState<Pacts[]>([]);
	const [selectedIndicatorToDelete, setSelectedIndicatorToDelete] = useState<SelectedToDelete>({} as SelectedToDelete);
	const [selectedIndicatorToExport, setSelectedIndicatorToExport] = useState('');

	const stepsEnum = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const { data: pactuados, isLoading: isPactuadosLoading } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['agreeds'],
		() => getSector(stepsEnum[DATA_CONTROL_STEPS[etapa!] as keyof typeof stepsEnum], stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !isEditing,
		},
	);

	const { mutate: lastActivePactMutation } = useMutation<
		IResponseLastActivePact[],
		AxiosError<ResponseErrors>,
		LastActivePactParams
	>(currentAgreed => getLastActivePact(currentAgreed), {
		onSuccess: data => {
			let initialYear = data?.at(-1)?.anoInicio!;
			let finalYear = data?.at(-1)?.anoFim!;

			if (initialYear === finalYear) {
				initialYear = initialYear + 1;
				setValue('anoInicial', initialYear);
			} else {
				setValue('anoInicial', finalYear);
			}

			setLastActivePact({
				ano: data?.at(-1)?.anoFim!,
				bimestre: data?.at(-1)?.bimestreInicio!,
			});

			setPactData({
				ano: data?.at(-1)?.anoFim!,
				bimestre: data?.at(-1)?.bimestreInicio!,
			});

			data.forEach((pacto, index) => {
				// desativa botão daquele ano quando pacto inicia e finaliza no mesmo ano
				if (pacto.anoInicio === pacto.anoFim) {
					setPactYearsToDisable(oldState => [...oldState, pacto.anoFim]);
				}

				// desativa botão daquele ano quando pacto inicia em um ano e finaliza no próximo ano
				if (index > 0 && data[index - 1].anoFim === pacto.anoInicio) {
					setPactYearsToDisable(oldState => [...oldState, pacto.anoInicio]);
				}

				// desativa botão daquele ano quando pacto inicia em 2023
				if (pacto.anoInicio === 2023) {
					setPactYearsToDisable(oldState => [...oldState, pacto.anoInicio]);
				}
			});
		},
		onError: ({ response }) => {
			if (response?.status !== 404 && response?.data?.message !== 'Pacto de Gestão não encontrado') {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			}

			if (response?.data?.message === 'Pacto de Gestão não encontrado') {
				let initialYear = getYear(new Date());
				let periodos = {} as LastActivePact;

				if (initialYear > 2023) {
					periodos = { ano: 2023, bimestre: 'QUARTO_BIMESTRE' };
					setValue('anoInicial', 2023);
				} else {
					setValue('anoInicial', initialYear);
					periodos = getLastBimester(initialYear, new Date());
				}

				setLastActivePact(periodos);
				setPactData(periodos);
			}
		},
	});

	const initialYear = watch('anoInicial');
	const finalYear = watch('anoFinal');
	const idUnidade = String(watch('pactuado.data.idUnidade'));

	const handleGetLastActivePact = (value: IOption) => {
		setPactYearsToDisable([]);
		if (value) {
			lastActivePactMutation({ pactuado: value.label });
		}
	};

	const queryEtapa = !!etapa ? DATA_CONTROL_STEPS[etapa as keyof typeof DATA_CONTROL_STEPS] : defaultValues?.etapa;

	const { data: indicadores, isLoading: isLoadingIndicadores } = useQuery<
		Indicator[],
		AxiosError<ResponseErrors>,
		Indicator[]
	>(
		['indicators', idUnidade, initialYear, finalYear, isEditing],
		() =>
			getIndicatorsPactLinkList({
				ano: [String(initialYear), String(finalYear)],
				etapa: queryEtapa,
				idUnidade,
			}),
		{
			onSuccess: () => {
				if (!fields.length) {
					append({ indicador: undefined, periodos: undefined });
				}
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!idUnidade && !!initialYear && !!finalYear && !isEditing,
		},
	);

	const { yearMin, yearMax: yearMaxDate } = getYearsForRange();
	const yearMinDate = isEditing ? defaultValues?.anoInicial! : yearMin;
	const hasInitialError = watch('anoInicial');

	// Settar Ano Final
	useEffect(() => {
		if (initialYear) {
			// ano inicial maior que ultimo pacto
			if (initialYear > lastActivePact.ano) {
				setValue('anoFinal', initialYear);
			}

			// ano inicial igual ano atual
			if (initialYear === getYear(new Date())) {
				const currentBimester = getBimester(new Date());
				let finalYear = initialYear;

				if (currentBimester > 1) {
					finalYear = finalYear + 1;
				}

				setValue('anoFinal', finalYear);
			}

			// ano inicial é igual ultimo pacto
			if (initialYear === lastActivePact.ano) {
				setValue('anoFinal', lastActivePact.ano + 1);
			}

			// ano inicial é menor ultimo pacto
			if (initialYear < lastActivePact.ano) {
				let finalYear = initialYear;

				if (lastActivePact.bimestre !== 'SEXTO_BIMESTRE') {
					finalYear = finalYear + 1;
				}

				setValue('anoFinal', finalYear);
			}

			// não tem ultimo pacto
			if (!lastActivePact.ano) {
				let finalYear = initialYear;

				// e ano inicial é igual ao ano atual
				if (initialYear === getYear(new Date())) {
					const currentBimester = getBimester(new Date());

					if (currentBimester > 1) {
						finalYear = finalYear + 1;
					}
				}

				setValue('anoFinal', finalYear);
			}

			//ano inicial é 2023 e está editando
			if (initialYear === 2023 && isEditing) {
				setValue('anoFinal', initialYear + 1);
			}
		}

		if (!initialYear) {
			setValue('anoFinal', initialYear);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialYear, lastActivePact]);

	const pactuado = watch('pactuado');

	// Mudar array de bimestres baseado na mudança de ano inicial
	useEffect(() => {
		if (initialYear && defaultValues?.anoInicial !== initialYear && pactuado?.value) {
			const oldForm = getValues('indicadores');

			let dataToChangePactArray = {} as LastActivePact;

			if (oldForm) {
				// caso ano inicial seja maior que o ano no pactData ou o ano inicial seja maior que o ano atual
				if (initialYear > pactData.ano || initialYear > getYear(new Date())) {
					dataToChangePactArray = { ano: initialYear - 1, bimestre: 'SEXTO_BIMESTRE' };
					const isEqualToLastActivePact = initialYear === lastActivePact.ano;

					// caso o ano inicial seja igual ao ano do último pacto ativo
					if (isEqualToLastActivePact) {
						dataToChangePactArray = lastActivePact;
					}

					// cria novo array de bimestres
					const newArray = getNextSixBimesters(dataToChangePactArray);

					// cria novo form com o novo array de bimestres
					const values = fields.map(item => ({
						...item,
						periodos: newArray,
					}));

					// criar form levando os valores do form antigo
					const newFormValues = values.map(item => {
						// filtra o form antigo para pegar os valores já preenchidos
						const currentIndicator = oldForm.find(indicador => indicador.indicador === item.indicador);
						const filteredYear = currentIndicator?.periodos?.find(
							periodo => periodo.ano === (isEqualToLastActivePact ? initialYear + 1 : initialYear),
						);
						const currentFormValues = filteredYear?.periodos?.filter(
							periodo => periodo.meta !== 100 || periodo.peso !== 1,
						);

						if (currentFormValues) {
							return {
								...item,
								periodos: item.periodos.map(periodo => ({
									...periodo,
									periodos: periodo.periodos.map(periodoItem => {
										const hasItem = currentFormValues.find(
											periodo => periodo.periodoAtribuicaoPactuado === periodoItem.periodoAtribuicaoPactuado,
										);

										if (hasItem) {
											return hasItem;
										}

										return periodoItem;
									}),
								})),
							};
						}

						return item;
					});

					replace(newFormValues as unknown as IPeriod[]);
				}

				// caso ano inicial seja 2023
				if (initialYear === 2023) {
					dataToChangePactArray = { ano: initialYear, bimestre: 'QUARTO_BIMESTRE' };

					// cria novo array de bimestres
					const newArray = getNextSixBimesters(dataToChangePactArray);

					// cria novo form com o novo array de bimestres
					const values = fields.map(item => ({
						...item,
						periodos: newArray,
					}));

					replace(values as unknown as IPeriod[]);
				}

				// caso ano inicial seja igual ao ano atual
				if (initialYear === getYear(new Date())) {
					const currentBimester = getBimester(new Date());
					const currentBimesterEnum =
						VALUE_TO_BIMESTERS_ENUM[String(currentBimester) as keyof typeof VALUE_TO_BIMESTERS_ENUM];

					if (
						!pactYearsToDisable.includes(lastActivePact.ano) &&
						lastActivePact.bimestre !== currentBimesterEnum &&
						lastActivePact.ano > initialYear
					) {
						dataToChangePactArray = lastActivePact;
					} else {
						dataToChangePactArray = getLastBimester(initialYear, new Date());
					}

					setValue('anoInicial', initialYear);

					// cria novo array de bimestres
					const newArray = getNextSixBimesters(dataToChangePactArray);

					// cria novo form com o novo array de bimestres
					const values = fields.map(item => ({
						...item,
						periodos: newArray,
					}));

					// criar form levando os valores do form antigo
					const newFormValues = values.map(item => {
						// filtra o form antigo para pegar os valores já preenchidos
						const currentIndicator = oldForm.find(indicador => indicador.indicador === item.indicador);
						const filteredYear = currentIndicator?.periodos?.find(periodo => periodo.ano === getYear(new Date()) + 1);
						const currentFormValues = filteredYear?.periodos.filter(
							periodo => periodo.meta !== 100 || periodo.peso !== 1,
						);

						if (currentFormValues) {
							return {
								...item,
								periodos: item.periodos.map(periodo => ({
									...periodo,
									periodos: periodo.periodos.map(periodoItem => {
										const hasItem = currentFormValues.find(
											periodo => periodo.periodoAtribuicaoPactuado === periodoItem.periodoAtribuicaoPactuado,
										);

										if (hasItem) {
											return hasItem;
										}

										return periodoItem;
									}),
								})),
							};
						}

						return item;
					});

					replace(newFormValues as unknown as IPeriod[]);
				}

				if (initialYear === lastActivePact.ano) {
					setPactData(lastActivePact);
				} else {
					setPactData(dataToChangePactArray);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialYear, lastActivePact.bimestre]);

	// Limpar select de indicadores
	useEffect(() => {
		const currentValues = watch(`indicadores`);

		currentValues?.forEach((item, index) => {
			const hasValue = isEditing
				? indicadoresEdit?.find(indicador => indicador.nome === item.indicador?.label)
				: indicadores?.find(indicador => indicador.nome === item.indicador?.label);

			if (!hasValue) {
				setValue(`indicadores.${index}.indicador`, undefined);
			}
		});
	}, [indicadores, indicadoresEdit, initialYear, isEditing, setValue, watch]);

	const indicatorMatrixId = getValues('indicadores');
	const pactuadoMatrixId = getValues('pactuado.value');

	const matrixIn = useMemo(() => {
		const matrixInId = indicatorMatrixId?.map(i => i.indicador?.value);
		if (matrixInId === undefined) {
			return;
		} else {
			return matrixInId;
		}
	}, [indicatorMatrixId]);

	const pactIn = useMemo(() => {
		const matrixInId = pactuados?.find(idPactMatrix => idPactMatrix.idSetor === pactuadoMatrixId);

		return {
			label: matrixInId?.nome,
			value: matrixInId?.idSetor,
		};
	}, [pactuadoMatrixId, pactuados]);

	let pactPeriodIn: IPeriodYear[] = [];
	useMemo(() => {
		const matrixInId = fields?.forEach(item => {
			pactPeriodIn =
				// eslint-disable-next-line react-hooks/exhaustive-deps
				item?.periodos?.map(i => {
					return i;
				}) || [];
		});

		return matrixInId;
	}, [fields, pactPeriodIn]);

	const onApllyAll = (index: number) => {
		const copyGoals = getValues('copyValues') && getValues('copyValues')?.find(item => item === 'copyGoals');
		const copyWeight = getValues('copyValues') && getValues('copyValues')?.find(item => item === 'copyWeight');
		const valuesToCopy = getValues('indicadores') && getValues('indicadores')?.[index]?.periodos;

		//Copiar peso para todos
		const values = fields?.map(item => ({
			...item,
			periodos: item?.periodos?.map((ano, indexAno) => ({
				...ano,
				periodos: ano.periodos?.map((periodo, indexPeriodo) => ({
					...periodo,
					peso: copyWeight && periodo.isEditavel ? valuesToCopy?.[indexAno].periodos[indexPeriodo].peso! : periodo.peso,
					meta: copyGoals && periodo.isEditavel ? valuesToCopy?.[indexAno].periodos[indexPeriodo].meta! : periodo.meta,
				})),
			})),
		}));

		replace(values);
	};

	const handleAddIndicator = () => {
		append({ indicador: undefined, periodos: undefined, request: 'POST' });
	};

	const handleAddPeriod = ({ data: currentIndicator }: IOption<Indicator>, index: number, code: string) => {
		const exists = fields.find(item => item.code === code);

		if (pactuado?.value) {
			if (isEditing) {
				let dataToChangePactArray = {} as LastActivePact;

				if (defaultValues?.indicadores?.length) {
					const initialYear = defaultValues?.anoInicial!;
					const firstBimester = defaultValues.indicadores[0].periodos?.[0].periodos[0].periodoAtribuicaoPactuado || '';
					const convertedBimester = TEXT_TO_BIMESTERS_ENUM[firstBimester as keyof typeof TEXT_TO_BIMESTERS_ENUM];

					const bimestre = convertedBimester;

					if (bimestre === 'SEXTO_BIMESTRE') dataToChangePactArray = { ano: initialYear - 1, bimestre };
					else dataToChangePactArray = { ano: initialYear, bimestre };
				}

				const newArray = getNextSixBimesters(dataToChangePactArray);

				update(index, {
					indicador: {
						label: currentIndicator?.nome || '',
						value: currentIndicator?.id || '',
					},
					periodos: newArray as IPeriodYear[],
					request: exists?.request,
				});
			} else {
				const newArray = getNextSixBimesters(pactData);

				update(index, {
					indicador: {
						label: currentIndicator?.nome || '',
						value: currentIndicator?.id || '',
					},
					periodos: newArray as IPeriodYear[],
				});
			}
		}
	};

	const getOptionSelect = (indicadores?: Indicator[]) => {
		let indicadoresCopy = indicadores?.filter((e: any) => {
			return !fields.find(item => {
				return item.indicador?.value === e.id;
			});
		});
		return parsedOptionArray(indicadoresCopy, 'nome', 'id');
	};

	const handleOpenDeleteModal = (indicator: Pacts, index: number) => {
		onDeleteModalOpen();
		setSelectedIndicatorToDelete({ ...indicator, index });
	};

	const handleDeleteIndicator = () => {
		resetField(`indicadores.${selectedIndicatorToDelete.index}`, {
			defaultValue: {},
		});
		if (selectedIndicatorToDelete.request === 'PUT')
			setIndicatorsDeleted(prev => [...prev, { ...selectedIndicatorToDelete, request: 'DELETE' }]);
		remove(selectedIndicatorToDelete.index);
		setSelectedIndicatorToDelete({} as SelectedToDelete);
		onDeleteModalClose();
	};

	const handleValidatePact = () => {
		onHandleValidate?.(getValues(), isDirty);
	};

	const onHandleSubmit = (values: IPactForm) => {
		const pactuado = pactuados?.find(pactuado => pactuado.idSetor === Number(values.pactuado?.value));
		let data: IPactForm = {
			...values,
			pactuado: {
				value: pactuado?.idSetor!,
				label: pactuado?.nome!,
				data: pactuado!,
			},
		};

		const filteredPutIndicators = data.indicadores?.filter(indicador => indicador.request === 'PUT');
		const filteredPostIndicators = data.indicadores?.filter(indicador => indicador.request === 'POST');

		const filteredPutWithoutDeletedIndicators = indicatorsDeleted?.length
			? filteredPutIndicators?.filter(indicador => indicatorsDeleted.find(deleted => deleted.id === indicador.id))
			: filteredPutIndicators;

		const indicatorsUpdated = formattedValues
			?.map(defaultIndicador => ({
				...defaultIndicador,
				periodos: defaultIndicador.periodos
					?.map(defaultPeriodoAno => {
						let updatedPeriods: IPeriod[] = [];

						defaultPeriodoAno.periodos?.forEach(defaultPeriodo => {
							if (defaultPeriodoAno.ano) {
								filteredPutWithoutDeletedIndicators?.forEach(indicator => {
									indicator.periodos?.forEach(periodoAno => {
										const period = periodoAno.periodos.find(
											periodo =>
												defaultPeriodo.id === periodo.id &&
												(periodo.meta !== defaultPeriodo.meta || periodo.peso !== defaultPeriodo.peso),
										);

										if (period) {
											updatedPeriods.push(period);
										}
									});
								});
							}
						});

						return {
							...defaultPeriodoAno,
							periodos: updatedPeriods,
						};
					})
					?.filter(item => item.periodos?.length),
			}))
			?.filter(indicador => indicador.periodos?.length) as Pacts[];

		if (isEditing) {
			data = {
				...data,
				indicadores: [...filteredPostIndicators!, ...indicatorsUpdated!, ...indicatorsDeleted],
			};
		}

		onSubmitForm(data);
	};

	const handleOpenExportModal = (indicadorId: string) => {
		setSelectedIndicatorToExport(indicadorId);
		onOpenExportModal();
	};

	const handleReplicateBimester = (value: IPeriodYear[], index: number) => {
		const newGoal = value[0].periodos?.[0].meta;
		const newWeight = value[0].periodos?.[0].peso;
		const dataToReplace = fields[index];

		const newValues = {
			...dataToReplace,
			periodos: dataToReplace?.periodos?.map(item => ({
				...item,
				periodos: item?.periodos?.map(periodo => {
					const _newGoal = newGoal ?? periodo.meta;
					const _newWeight = newWeight ?? periodo.peso;
					return {
						...periodo,
						meta: periodo.isEditavel ? _newGoal : periodo.meta,
						peso: periodo.isEditavel ? _newWeight : periodo.peso,
					};
				}),
			})),
		};

		update(index, newValues);
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.managementPact);

		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const secondStepPermissions = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const isRepresentative = useCanViewAction([USER_ROLES.REPRESENTANTE]);

	if (etapa === 'etapa-2' && isRepresentative && !secondStepPermissions) return <Navigate to={ROUTES.home} replace />;

	if (isEditing && defaultValues?.etapa === STEPS_ENUM.ETAPA_UM && !firstStepPermission) {
		return <Navigate to={ROUTES.home} replace />;
	}

	return (
		<>
			<Box as="form" onSubmit={handleSubmit(onHandleSubmit)}>
				{isPactuadosLoading && !isEditing ? (
					<Flex sx={styles.spinner}>
						<Spinner />
					</Flex>
				) : (
					<>
						<Box sx={styles.container} pos="relative">
							{isEditing && (
								<Box pos="absolute" right={0}>
									<IndicatorsFormValidationHover
										onValidationCheck={handleValidatePact}
										validationDate={validationDate}
									/>
								</Box>
							)}
							<Flex sx={styles.fields}>
								<Box sx={styles.pact}>
									<Controller
										name="pactuado"
										control={control}
										render={({ field }) => (
											<Select
												label="Pactuado"
												dataTestId="input--agreed-name"
												placeholder="Selecione um pactuado"
												value={field?.value}
												onChange={e => {
													field?.onChange(e);
													handleGetLastActivePact(e as unknown as IOption);
												}}
												errorMessage={errors?.pactuado?.message}
												options={parsedOptionArray(pactuados, 'nome', 'idSetor')}
												isDisabled={isEditing}
												isLoading={isPactuadosLoading}
												required
											/>
										)}
									/>
								</Box>

								<Box sx={styles.years}>
									<CustomCalendar
										label="Ano inicial"
										placeholder="Selecione um ano"
										fieldName="anoInicial"
										dataTestId="input--year"
										errorMessage={!hasInitialError ? errors.anoInicial?.message : ''}
										setValue={setValue}
										minDate={new Date(yearMinDate, 0, 1)}
										maxDate={new Date(yearMaxDate, 0, 1)}
										control={control}
										isDisabled={isEditing}
										required
										defaultValue={defaultValues?.anoInicial || initialYear}
										yearsToDisable={pactYearsToDisable}
									/>
								</Box>
								<Box sx={styles.years}>
									<InputText
										label="Ano final"
										name="anoFinal"
										isReadOnly
										register={register}
										placeholder="Ano final"
										fontSize="1rem"
										isDisabled={isEditing}
									/>
								</Box>
							</Flex>

							{fields?.[0] && <Divider color="gray.95" />}
						</Box>
						{fields?.map(({ periodos, ...item }, index) => (
							<Box key={item?.code} sx={styles.container}>
								<HStack sx={styles.stack}>
									<Box sx={styles.pactSelected}>
										<Controller
											name={`indicadores.${index}.indicador`}
											control={control}
											render={({ field }) => (
												<Select
													label="Nome do indicador"
													dataTestId={`input--indicator-${index}`}
													placeholder="Selecione um indicador"
													value={field.value}
													onChange={event => {
														field?.onChange(event);
														handleAddPeriod(event as unknown as IOption<Indicator>, index, item.code);
													}}
													isEditing={isEditing && !!dirtyFields.indicadores?.[index]?.indicador?.value}
													options={isEditing ? getOptionSelect(indicadoresEdit) : getOptionSelect(indicadores)}
													errorMessage={errors?.indicadores?.[index]?.indicador?.message}
													isLoading={isEditing ? isIndicadoresEditLoading : isLoadingIndicadores}
													isDisabled={
														isEditing &&
														!!item.indicador?.value &&
														formattedValues.find(
															defaultIndicator => defaultIndicator.indicador?.value === item.indicador?.value,
														)?.request === 'PUT' &&
														indicatorsDeleted.find(deleted => deleted.indicador?.value === item.indicador?.value)
															?.request !== 'DELETE'
													}
													required
												/>
											)}
										/>
										<Flex>
											{watch(`indicadores.${index}.indicador`) && (
												<Stack sx={styles.tooltipBtn}>
													<Tooltip
														label="Visualizar Matriz"
														color="#3B3333"
														bg="white"
														hasArrow
														arrowSize={15}
														placement="top-start"
														fontSize="sm"
													>
														<IconButton
															aria-label={'Visualizar Matriz'}
															variant="unstyled"
															icon={<ViewMatrixIcon />}
															data-testid="pact--view-matrix"
															onClick={() =>
																handleOpenExportModal(String(getValues(`indicadores.${index}.indicador.value`)))
															}
														/>
													</Tooltip>
												</Stack>
											)}
										</Flex>
									</Box>

									<Flex sx={styles.checkbox}>
										<Controller
											name="copyValues"
											control={control}
											defaultValue={['copyWeight']}
											// eslint-disable-next-line unused-imports/no-unused-vars
											render={({ field: { ref, ...rest } }) => (
												<CheckboxGroup {...rest}>
													<Flex sx={styles.checkboxContainer}>
														<Text sx={styles.inputLabel}>Replicar para todos bimestres:</Text>
														<Checkbox
															sx={styles.checkboxInput}
															value="copyGoals"
															data-testid={`checkbox--copyGoals.${index}`}
														>
															Metas
														</Checkbox>
														<Checkbox
															sx={styles.checkboxInput}
															value="copyWeight"
															data-testid={`checkbox--copyWeight.${index}`}
														>
															Pesos
														</Checkbox>
														<Button
															variant="primary"
															sx={styles.buttonApply}
															onClick={() => onApllyAll(index)}
															data-testid={`button--apply.${index}`}
														>
															Aplicar
														</Button>
													</Flex>
												</CheckboxGroup>
											)}
										/>
									</Flex>

									{fields.length > 1 &&
										!(
											isEditing &&
											periodos
												?.map(item => item?.periodos?.filter(item2 => !item2?.isEditavel))
												?.filter(item3 => item3?.length)?.length
										) && (
											<Box sx={styles.boxBtnRmv}>
												<Button
													sx={styles.buttonRemove}
													variant="unstyled"
													leftIcon={<IndicatorPactDelete />}
													onClick={() => handleOpenDeleteModal({ ...item, periodos }, index)}
													data-testid={`button--remove-indicator-${index}`}
												>
													Remover Indicador
												</Button>
											</Box>
										)}
								</HStack>

								{periodos && initialYear && (
									<GoalGroup
										control={control}
										isReadOnly={isReadOnly}
										nestedIndex={index}
										fieldErrors={errors}
										isEditing={isEditing}
										onHandleReplicateBimester={() =>
											handleReplicateBimester(watch('indicadores')?.[index]?.periodos || [], index)
										}
									/>
								)}
							</Box>
						))}
						<Box sx={styles.buttonContainer}>
							<Button
								sx={styles.buttonAdd}
								leftIcon={<SmallAddIcon />}
								data-testid="button--add-array"
								onClick={handleAddIndicator}
							>
								Adicionar Novo Indicador
							</Button>
						</Box>
						<Box sx={styles.actions}>
							<Box sx={styles.actionsButtons}>
								<Button
									type="submit"
									sx={styles.button}
									data-testid="button--submit"
									variant="primary"
									isLoading={isSubmitLoading}
								>
									Salvar
								</Button>
								<Button sx={styles.button} variant="secondary" data-testid="button--cancel" onClick={handleOpenModal}>
									Cancelar
								</Button>
							</Box>
						</Box>
					</>
				)}
				{selectedIndicatorToExport && (
					<IndicatorMatrix
						indicatorId={selectedIndicatorToExport}
						onClose={onCloseExportModal}
						isOpen={isOpenExportModal}
						key={`${matrixIn}-${pactIn.value}-${pactIn.label}`}
					/>
				)}

				<CustomModal
					icons={[{ type: 'error' }]}
					title="Você realmente quer cancelar?"
					body="Todas as informações serão perdidas caso você cancele."
					isOpen={isOpen}
					onClose={onClose}
					actions={[
						{
							label: 'Quero Cancelar',
							type: 'cancel',
							onClick: handleGoBack,
							datatestid: 'button--confirm',
						},
						{
							label: 'Voltar',
							type: 'secondary',
							onClick: handleCloseModal,
							datatestid: 'button--cancel',
						},
					]}
				/>
				<CustomModal
					icons={[{ type: 'error' }]}
					title="Você irá remover esse indicador."
					body="Esse processo não pode ser desfeito."
					isOpen={isDeleteModalOpen}
					onClose={onDeleteModalClose}
					actions={[
						{
							label: 'Quero Remover',
							type: 'cancel',
							onClick: handleDeleteIndicator,
							datatestid: 'button--confirm',
						},
						{
							label: 'Voltar',
							type: 'secondary',
							onClick: onDeleteModalClose,
							datatestid: 'button--cancel',
						},
					]}
				/>
			</Box>
			<Prompt when={showPrompt} />
		</>
	);
};

export default PactForm;
