/**
 *
 * ResultEvaluationScreen
 *
 */

import React, { useMemo, useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Button, Flex, Spinner, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import ContinuousImprovementModal from 'app/components/ContinuousImprovementModal';
import CustomModal from 'app/components/CustomModal';
import FileModal, { FileTypes, IFile, IModalDocs } from 'app/components/FileModal';
import PageWrapper from 'app/components/PageWrapper';
import ResultEvaluationForm, { IResultEvaluationForm } from 'app/components/ResultEvaluationForm';
import ResultEvaluationVotingForm, { IVotingRecord } from 'app/components/ResultEvaluationVotingForm';
import ResultsEvaluationIndicatorCard, {
	IResultsEvaluationIndicatorCardData,
} from 'app/components/ResultsEvaluationIndicatorCard';
import ResultsEvaluationIndicatorChart from 'app/components/ResultsEvaluationIndicatorChart';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getCaapMembersList } from 'services/http/caapMember';
import {
	createAppealFirstInstancePhase,
	createAppealSecondInstancePhase,
	createCaapDecisionPhase,
	createContinuousImprovementRequest,
	createCounterReasonsPhase,
	createDecisionFirstInstancePhase,
	createLaunchPhase,
	createValidationPhase,
	deleteAppealFirstInstancePhase,
	deleteAppealSecondInstancePhase,
	deleteCaapDecisionPhase,
	deleteContinuousImprovementArchives,
	deleteCounterReasonsPhase,
	deleteDecisionFirstInstancePhase,
	deleteLaunchPhase,
	deleteValidationPhase,
	downloadFiles,
	getAppealFirstInstancePhaseNext,
	getAppealSecondInstancePhaseNext,
	getContinuousImprovementArchives,
	getContinuousImprovementRequest,
	getCounterReasonsPhaseNext,
	getDecisionFirstInstancePhaseNext,
	getLaunchPhaseNext,
	getResultEvaluation,
	getResultEvaluationGraphic,
	getResultEvaluationSummary,
	getValidationPhaseNext,
	updateAppealFirstInstancePhase,
	updateAppealSecondInstancePhase,
	updateCaapDecisionPhase,
	updateContinuousImprovementRequest,
	updateCounterReasonsPhase,
	updateDecisionFirstInstancePhase,
	updateLaunchPhase,
	updateValidationPhase,
	uploadAppealFirstInstancePhase,
	uploadAppealSecondInstancePhase,
	uploadCaapDecisionPhase,
	uploadContinuousImprovement,
	uploadCounterReasonsPhase,
	uploadDecisionFirstInstancePhase,
	uploadLaunchPhase,
	uploadValidationPhase,
} from 'services/http/resultEvaluation';
import { CaapMembers } from 'types/caapMembers';
import {
	IContinuousImprovementRequest,
	IDownloadFileRequest,
	IResponseContinuousImprovement,
	IResponseContinuousImprovementRequest,
	IResultEvaluation,
	IResultEvaluationDocument,
	IResultEvaluationGraphic,
	IResultEvaluationRequest,
	IResultEvaluationResponse,
	IResultEvaluationSummary,
	IUploadContinuousImprovement,
} from 'types/resultEvaluation';
import {
	ALLOWED_EXTENSIONS,
	API_DEFAULT_ERROR,
	BIMESTERS_ENUM_NUMBERS,
	CONTINUOUS_IMPROVEMENT_ENUM,
	CONTINUOUS_IMPROVEMENT_FORM_TO_ENUM,
	RESULT_EVALUATION,
	RESULT_EVALUATION_APPEAL,
	RESULT_EVALUATION_PHASES,
	STEPS_ENUM,
	TOTAL_PERCENTAGE,
	USER_ROLES,
} from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { formatFromStringToNumber } from 'utils/formatBimesters';
import { formatDesempenhoEnum } from 'utils/formatEnum';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { formatVotingData } from 'utils/formatVotingData';
import { isFileSizeValid } from 'utils/isFileSizeValid';
import { maskThousands, unMaskThousands } from 'utils/Numbers';
import { ResponseErrors } from 'utils/parseErrors';
import * as yup from 'yup';

export interface IForm {
	id?: string | number;
	solicitacaoPlanoAcao: boolean;
	solicitacaoCausaRaiz: boolean;
	solicitacaoAnaliseCritica: boolean;
}

type IParams = {
	idIndicadorPacto: string;
};

export const formatCalcWeight = (weigth: number) => {
	const pesoCalculado = Number(weigth) * TOTAL_PERCENTAGE;
	const pesoCalculadoPorcentagem = formatDecimalNumber(pesoCalculado, undefined, 2, 'halfEven');

	return { pesoCalculado, pesoCalculadoPorcentagem };
};

const ResultEvaluationScreen = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		wrapper: {
			mb: '0.75rem',
		},
		loadingContainer: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		buttonContainer: {
			justifyContent: 'flex-end',
			mb: '0.5rem',
			gap: '1rem',
		},
		button: {
			pl: '1.375rem',
			pr: '0.5rem',
			fontSize: '1rem',
			h: '2.75rem',
		},
		content: {
			flexDir: 'column',
			mb: '9rem',
			gap: '2.5rem',
		},
		graphicContainer: {
			gap: {
				base: '2.5rem',
				xl: '4.188rem',
			},

			flexDir: {
				base: 'column',
				xl: 'row',
			},
		},
		graphicContent: {
			w: '100%',
			maxW: {
				base: '100%',
				xl: '39.45rem',
			},
		},
		formContainer: {
			flexDir: 'column',
			gap: '2rem',
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const {
		session: { user },
	} = useSession();
	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();
	const { idIndicadorPacto = '' } = useParams<IParams>();

	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const [data, setData] = useState<IResultEvaluationForm[]>([]);
	let dataWithVotes: IResultEvaluationForm[] = [];

	const fileSchema = yup
		.mixed()
		.test('size', 'Tamanho do arquivo não pode ser maior que 10mb.', (value: IFile) => {
			const isValidSize = isFileSizeValid(value?.size!);

			if (!isValidSize && value) {
				onOpen();
				return false;
			}

			return true;
		})
		.test('type', 'Selecione um arquivo do tipo .pdf de até 10mb.', (value: IFile) => {
			const isValid = ALLOWED_EXTENSIONS.includes(value?.type!);

			if (!isValid && value) {
				onOpen();
				return !isValid;
			}

			return isValid;
		});

	const schemaArchives = yup.object().shape({
		planoAcao: fileSchema,
		causaRaiz: fileSchema,
		analiseCritica: fileSchema,
	});

	const {
		setValue,
		control,
		setError,
		formState: { errors },
	} = useForm<IModalDocs>({ resolver: yupResolver(schemaArchives), mode: 'onChange' });

	// RESUMO
	const {
		data: summary,
		isLoading: isSummaryLoading,
		refetch: refetchSummary,
	} = useQuery<IResultEvaluationSummary, AxiosError<ResponseErrors>>(
		['resumo', idIndicadorPacto],
		() => getResultEvaluationSummary(idIndicadorPacto),
		{
			onSuccess: values => {
				if (!data.length) {
					const { pesoCalculado, pesoCalculadoPorcentagem } = formatCalcWeight(values.pesoCalculado);

					const defaultValue: IResultEvaluationForm = {
						pactuado: values.pactuado,
						nomeIndicador: values.nomeIndicador,
						bimestre: values.bimestre,
						faseEnum: RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO,
						responsavelLancamento: user.nomeCompleto,
						pesoCalculado: pesoCalculado < 0 ? 'N/A' : pesoCalculadoPorcentagem,
						polaridade: values.polaridade,
						idIndicadoresPactoGestao: Number(idIndicadorPacto),
						ano: String(values.ano),
						meta: maskThousands(values.meta),
						etapa: values.etapa,
						tipoResponsavelLancamento: values.responsavelLancamento,
						nomeAreaTecnica: values.nomeAreaTecnica,
					};

					setData([defaultValue]);
				}
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// GRÁFICO
	const {
		data: graphicData,
		isLoading: isGraphicDataLoading,
		refetch: refetchGraphic,
	} = useQuery<IResultEvaluationGraphic[], AxiosError<ResponseErrors>>(
		['grafico', idIndicadorPacto, data],
		() => getResultEvaluationGraphic(idIndicadorPacto),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// MELHORIA CONTÍNUA
	const { data: continuousImprovementData, refetch: refetchContinuousImprovementArchives } = useQuery<
		IResponseContinuousImprovement[],
		AxiosError<ResponseErrors>
	>(['documentos-melhoria-continua', idIndicadorPacto], () => getContinuousImprovementArchives(idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
		enabled: !!data.length,
	});

	const { mutate: uploadContinuousImprovementMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IUploadContinuousImprovement
	>(data => uploadContinuousImprovement(idIndicadorPacto, data), {
		onSuccess: () => {
			refetchContinuousImprovementArchives();
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deteleContinuousImprovementMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteContinuousImprovementArchives(data),
		{
			onSuccess: () => {
				refetchContinuousImprovementArchives();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { data: continuousImprovementRequest, refetch: refetchContinuousImprovementRequest } = useQuery<
		IResponseContinuousImprovementRequest,
		AxiosError<ResponseErrors>
	>(['solicitar-melhoria-continua', idIndicadorPacto], () => getContinuousImprovementRequest(idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: createContinuousImprovementMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IContinuousImprovementRequest
	>(data => createContinuousImprovementRequest(idIndicadorPacto, data), {
		onSuccess: () => {
			refetchContinuousImprovementRequest();
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: updateContinuousImprovementMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IContinuousImprovementRequest
	>(data => updateContinuousImprovementRequest(idIndicadorPacto, data), {
		onSuccess: () => {
			refetchContinuousImprovementRequest();
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: downloadFilesMutate } = useMutation<Blob, AxiosError<ResponseErrors>, IDownloadFileRequest>(
		data => downloadFiles(data),
		{
			onSuccess: (data, variables) => {
				const href = URL.createObjectURL(data);

				downloadFile(href, variables.fileName);

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// MEMBROS CAAP
	const { data: caapMembersData } = useQuery<CaapMembers[], AxiosError<ResponseErrors>>(
		['lista-membros-caap'],
		() => getCaapMembersList(),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// LISTAGEM DAS FASES
	const {
		isLoading: isAllPhasesLoading,
		refetch: refetchAllPhases,
		isRefetching: isRefetchingAllPhases,
	} = useQuery<IResultEvaluationResponse[], AxiosError<ResponseErrors>>(
		['fases', idIndicadorPacto],
		() => getResultEvaluation(idIndicadorPacto),
		{
			onSuccess: values => {
				if (values?.length) {
					const newData: IResultEvaluationForm[] = values?.map(item => {
						const { pesoCalculadoPorcentagem } = formatCalcWeight(item?.pesoCalculado!);

						return {
							...item!,
							ano: String(item.ano),
							meta: maskThousands(item.meta!),
							resultadoTotal: item?.resultadoTotal === -1 ? undefined : maskThousands(item?.resultadoTotal!),
							desempenho: item?.resultadoTotal === -1 ? undefined : maskThousands(item?.desempenho!),
							pesoCalculado: item?.resultadoTotal === -1 ? undefined : pesoCalculadoPorcentagem,
							pontosObtidos: item?.resultadoTotal === -1 ? undefined : formatDecimalNumber(item?.pontosObtidos!),
							naoSeAplica: item?.resultadoTotal === -1 ? true : false,
							statusDeferimento: item?.statusDeferimento ? RESULT_EVALUATION.DEFERIDO : RESULT_EVALUATION.INDEFERIDO,
							ultimaAtualizacao: item?.ultimaAtualizacao,
							tipoResponsavelLancamento: item.tipoResponsavelLancamento,
							nomeAreaTecnica: summary?.nomeAreaTecnica,
							documentos:
								item?.documentos?.map(documento => ({
									id: String(documento.id)!,
									name: documento.titulo!,
									linkBy: documento.path!,
									size: documento.tamanhoArquivo!,
									type: `application/${documento.tipoArquivo.toLowerCase()}`,
								})) || [],
							votacoes: {
								...item.votacoes!,
								totalVotos: item.votacoes?.registrosVotacao.length,
								registrosVotacao:
									item.votacoes?.registrosVotacao?.map(registro => {
										const representation = caapMembers?.find(caap => Number(caap.id) === registro.representacoes.id);

										return {
											idVoto: registro.id,
											titular: representation?.titular!,
											suplente: representation?.suplente!,
											voto: registro?.voto!,
											representacao: registro.representacoes.representacao,
											idRepresentacao: registro.representacoes.id,
										};
									}) || [],
							},
						};
					});

					setData(newData);
				} else {
					const { pesoCalculadoPorcentagem } = formatCalcWeight(summary?.pesoCalculado!);

					const defaultValue: IResultEvaluationForm = {
						pactuado: summary?.pactuado,
						nomeIndicador: summary?.nomeIndicador,
						bimestre: summary?.bimestre,
						faseEnum: RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO,
						responsavelLancamento: user.nomeCompleto,
						pesoCalculado: pesoCalculadoPorcentagem,
						polaridade: summary?.polaridade,
						idIndicadoresPactoGestao: Number(idIndicadorPacto),
						ano: String(summary?.ano),
						meta: maskThousands(summary?.meta!),
						etapa: summary?.etapa,
						tipoResponsavelLancamento: summary?.responsavelLancamento,
						nomeAreaTecnica: summary?.nomeAreaTecnica,
					};

					setData([defaultValue]);
				}
				refetchSummary();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
			enabled: !!data.length,
		},
	);

	let isPhaseSuccessfullyCreated = false;
	let isPhaseSuccessfullyUpdated = false;

	// LANÇAMENTO DE RESULTADOS
	const { mutateAsync: uploadLaunchPhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadLaunchPhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createLaunchPhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, IResultEvaluation>(
		data => createLaunchPhase(data),
		{
			onSuccess: () => {
				isPhaseSuccessfullyCreated = true;
				refetchAllPhases();
				refetchGraphic();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutateAsync: updateLaunchPhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, IResultEvaluation>(
		data => updateLaunchPhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
				isPhaseSuccessfullyUpdated = true;
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: deleteLaunchPhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteLaunchPhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: getLaunchPhaseNextMutation } = useMutation<IResultEvaluation, AxiosError<ResponseErrors>>(
		() => getLaunchPhaseNext(idIndicadorPacto),
		{
			onSuccess: values => {
				const hasValue = data.find(item => item.faseEnum === values.faseEnum);

				if (!hasValue) {
					const { pesoCalculadoPorcentagem } = formatCalcWeight(values?.pesoCalculado!);

					const newData: IResultEvaluationForm = {
						pactuado: values.pactuado,
						nomeIndicador: values.nomeIndicador,
						ano: String(values.ano),
						polaridade: values.polaridade,
						bimestre: values.bimestre,
						faseEnum: values.faseEnum,
						idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
						meta: maskThousands(values?.meta!),
						responsavelLancamento: user.nomeCompleto,
						pesoCalculado: pesoCalculadoPorcentagem,
						etapa: summary?.etapa,
						nomeAreaTecnica: summary?.nomeAreaTecnica,
					};

					setData(oldState => [...oldState, newData]);
				}
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// VALIDAÇÃO DO RESULTADO
	const { mutateAsync: uploadValidationPhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadValidationPhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createValidationPhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => createValidationPhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyCreated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: updateValidationPhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => updateValidationPhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyUpdated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deleteValidationPhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteValidationPhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: getValidationPhaseNextMutation } = useMutation<IResultEvaluation, AxiosError<ResponseErrors>>(
		() => getValidationPhaseNext(idIndicadorPacto),
		{
			onSuccess: values => {
				const { pesoCalculadoPorcentagem } = formatCalcWeight(values?.pesoCalculado!);

				const newData: IResultEvaluationForm = {
					pactuado: values.pactuado,
					nomeIndicador: values.nomeIndicador,
					ano: String(values.ano),
					polaridade: values.polaridade,
					bimestre: values.bimestre,
					faseEnum: values.faseEnum,
					idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
					meta: maskThousands(values?.meta!),
					responsavelLancamento: user.nomeCompleto,
					pesoCalculado: pesoCalculadoPorcentagem,
					etapa: summary?.etapa,
					nomeAreaTecnica: summary?.nomeAreaTecnica,
				};

				setData(oldState => [...oldState, newData]);
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// RECORRER 1ª INSTÂNCIA
	const { mutateAsync: uploadAppealFirstInstancePhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadAppealFirstInstancePhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createAppealFirstInstancePhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => createAppealFirstInstancePhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyCreated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: updateAppealFirstInstancePhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => updateAppealFirstInstancePhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyUpdated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deleteAppealFirstInstancePhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteAppealFirstInstancePhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: getAppealFirstInstancePhaseNextMutation } = useMutation<
		IResultEvaluation,
		AxiosError<ResponseErrors>
	>(() => getAppealFirstInstancePhaseNext(idIndicadorPacto), {
		onSuccess: values => {
			const { pesoCalculadoPorcentagem } = formatCalcWeight(values?.pesoCalculado!);

			const newData: IResultEvaluationForm = {
				pactuado: values.pactuado,
				nomeIndicador: values.nomeIndicador,
				ano: String(values.ano),
				polaridade: values.polaridade,
				bimestre: values.bimestre,
				faseEnum: values.faseEnum,
				idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
				meta: maskThousands(values?.meta!),
				responsavelLancamento: user.nomeCompleto,
				pesoCalculado: pesoCalculadoPorcentagem,
				solicitacaoEnum: data.at(-1)?.solicitacaoEnum,
				etapa: summary?.etapa,
				nomeAreaTecnica: summary?.nomeAreaTecnica,
			};

			setData(oldState => [...oldState, newData]);
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	// DECISÃO 1ª INSTÂNCIA
	const { mutateAsync: uploadDecisionFirstInstancePhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadDecisionFirstInstancePhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createDecisionFirstInstancePhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => createDecisionFirstInstancePhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyCreated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: updateDecisionFirstInstancePhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => updateDecisionFirstInstancePhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyUpdated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deleteDecisionFirstInstancePhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteDecisionFirstInstancePhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: getDecisionFirstInstancePhaseNextMutation } = useMutation<
		IResultEvaluation,
		AxiosError<ResponseErrors>
	>(() => getDecisionFirstInstancePhaseNext(idIndicadorPacto), {
		onSuccess: values => {
			const { pesoCalculadoPorcentagem } = formatCalcWeight(values?.pesoCalculado!);

			const newData: IResultEvaluationForm = {
				pactuado: values.pactuado,
				nomeIndicador: values.nomeIndicador,
				ano: String(values.ano),
				polaridade: values.polaridade,
				bimestre: values.bimestre,
				faseEnum: values.faseEnum,
				idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
				meta: maskThousands(values?.meta!),
				responsavelLancamento: user.nomeCompleto,
				pesoCalculado: pesoCalculadoPorcentagem,
				etapa: summary?.etapa,
				nomeAreaTecnica: summary?.nomeAreaTecnica,
			};

			setData(oldState => [...oldState, newData]);
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	// RECORRER 2ª INSTÂNCIA
	const { mutateAsync: uploadAppealSecondInstancePhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadAppealSecondInstancePhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createAppealSecondInstancePhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => createAppealSecondInstancePhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyCreated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: updateAppealSecondInstancePhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => updateAppealSecondInstancePhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyUpdated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deleteAppealSecondInstancePhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteAppealSecondInstancePhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: getAppealSecondInstancePhaseNextMutation } = useMutation<
		IResultEvaluation,
		AxiosError<ResponseErrors>
	>(() => getAppealSecondInstancePhaseNext(idIndicadorPacto), {
		onSuccess: values => {
			const solicitacaoEnum = data.find(
				item => item.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA,
			)?.solicitacaoEnum;
			const { pesoCalculadoPorcentagem } = formatCalcWeight(values?.pesoCalculado!);

			const newData: IResultEvaluationForm = {
				pactuado: values.pactuado,
				nomeIndicador: values.nomeIndicador,
				ano: String(values.ano),
				polaridade: values.polaridade,
				bimestre: values.bimestre,
				faseEnum: values.faseEnum,
				idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
				meta: maskThousands(values?.meta!),
				responsavelLancamento: user.nomeCompleto,
				pesoCalculado: pesoCalculadoPorcentagem,
				etapa: summary?.etapa,
				solicitacaoEnum,
				nomeAreaTecnica: summary?.nomeAreaTecnica,
			};

			setData(oldState => [...oldState, newData]);
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	// CONTRARRAZOES 2ª INSTÂNCIA
	const { mutateAsync: uploadCounterReasonsPhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadCounterReasonsPhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createCounterReasonsPhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => createCounterReasonsPhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyCreated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: updateCounterReasonsPhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluation
	>(data => updateCounterReasonsPhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyUpdated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deleteCounterReasonsPhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteCounterReasonsPhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: getCounterReasonsPhaseNextMutation } = useMutation<IResultEvaluation, AxiosError<ResponseErrors>>(
		() => getCounterReasonsPhaseNext(idIndicadorPacto),
		{
			onSuccess: values => {
				const hasVotes = dataWithVotes.find(item => item.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP)?.votacoes;

				if (hasVotes) {
					setData(dataWithVotes);
					return;
				}

				const solicitacaoEnum = data.find(
					item => item.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA,
				)?.solicitacaoEnum;

				const { pesoCalculadoPorcentagem } = formatCalcWeight(values?.pesoCalculado!);

				const newData: IResultEvaluationForm = {
					pactuado: values.pactuado,
					nomeIndicador: values.nomeIndicador,
					ano: String(values.ano),
					polaridade: values.polaridade,
					bimestre: values.bimestre,
					faseEnum: values.faseEnum,
					idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
					meta: maskThousands(values?.meta!),
					responsavelLancamento: user.nomeCompleto,
					pesoCalculado: pesoCalculadoPorcentagem,
					solicitacaoEnum,
					etapa: summary?.etapa,
					nomeAreaTecnica: summary?.nomeAreaTecnica,
				};

				setData(oldState => [...oldState, newData]);
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	// DECISÃO CAAP
	const { mutateAsync: uploadCaapDecisionPhaseMutation } = useMutation<
		IResultEvaluationDocument[],
		AxiosError<ResponseErrors>,
		File[]
	>(files => uploadCaapDecisionPhase(files, idIndicadorPacto), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: createCaapDecisionPhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluationRequest
	>(data => createCaapDecisionPhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyCreated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutateAsync: updateCaapDecisionPhaseMutation } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IResultEvaluationRequest
	>(data => updateCaapDecisionPhase(data), {
		onSuccess: () => {
			refetchAllPhases();
			isPhaseSuccessfullyUpdated = true;
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: deleteCaapDecisionPhaseMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteCaapDecisionPhase(data),
		{
			onSuccess: () => {
				refetchAllPhases();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const chart = useMemo(() => {
		let resultados: number[] = [];
		let metas: number[] = [];

		graphicData?.forEach(item => {
			resultados.push(item.resultadoTotal && Number(item.resultadoTotal.toFixed(2)));
			metas.push(item.meta);
		});

		const currBimesterIndex = graphicData?.findIndex(item => item?.bimestre === summary?.bimestre);

		const desempenho = graphicData?.at(currBimesterIndex!)?.desempenho || 0;
		const meta = graphicData?.at(currBimesterIndex!)?.meta!;
		const resulDataAnterior = currBimesterIndex === 0 ? 0 : graphicData?.at(currBimesterIndex! - 1)?.resultadoTotal!;

		const sortedMeta = graphicData && [...graphicData];

		const topIndicator = sortedMeta?.sort((a, b) => b.meta - a.meta)?.at(0)?.meta!;
		const indicatorStatus =
			currBimesterIndex === 0
				? 'NEUTRO'
				: formatDesempenhoEnum(desempenho!, graphicData?.at(currBimesterIndex! - 1)?.desempenho!);

		const categories = graphicData?.map(item => ({
			axiX: BIMESTERS_ENUM_NUMBERS[item.bimestre as keyof typeof BIMESTERS_ENUM_NUMBERS],
			tooltip: `${BIMESTERS_ENUM_NUMBERS[item.bimestre as keyof typeof BIMESTERS_ENUM_NUMBERS]} Bim. ${item.ano}`,
		}));

		return {
			resultados,
			metas,
			desempenho,
			meta,
			resulDataAnterior,
			topIndicator,
			indicatorStatus,
			categories,
		};
	}, [summary?.bimestre, graphicData]);

	const card: IResultsEvaluationIndicatorCardData = useMemo(() => {
		const { pesoCalculadoPorcentagem } = formatCalcWeight(summary?.pesoCalculado!);
		return {
			...summary!,
			idIndicador: String(summary?.idIndicador),
			resultadoTotal: summary?.resultadoTotal || 0,
			desempenhoEnum: chart.indicatorStatus,
			pontuacao:
				summary?.pesoCalculado! < 0
					? 'N/A'
					: `${formatDecimalNumber(summary?.pontosObtidos) || 0} / ${pesoCalculadoPorcentagem}`,
		};
	}, [summary, chart.indicatorStatus]);

	const archives = useMemo(() => {
		const parsedPlanoAcao = continuousImprovementData?.find(
			item => item.tipoDocumento === CONTINUOUS_IMPROVEMENT_ENUM.PLANO_DE_ACAO,
		);

		const parsedCausaRaiz = continuousImprovementData?.find(
			item => item.tipoDocumento === CONTINUOUS_IMPROVEMENT_ENUM.CAUSA_RAIZ,
		);

		const parsedAnaliseCritica = continuousImprovementData?.find(
			item => item.tipoDocumento === CONTINUOUS_IMPROVEMENT_ENUM.ANALISE_CRITICA,
		);

		const formatedFiles = {
			planoAcao: {
				id: parsedPlanoAcao?.id,
				name: parsedPlanoAcao?.titulo,
				size: parsedPlanoAcao?.tamanhoArquivoEmMb,
				// type: parsedPlanoAcao?.tipo,
				linkBy: parsedPlanoAcao?.caminho,
				file: '',
			},
			causaRaiz: {
				id: parsedCausaRaiz?.id,
				name: parsedCausaRaiz?.titulo,
				size: parsedCausaRaiz?.tamanhoArquivoEmMb,
				// type: parsedCausaRaiz?.tipo,
				linkBy: parsedCausaRaiz?.caminho,
				file: '',
			},
			analiseCritica: {
				id: parsedAnaliseCritica?.id,
				name: parsedAnaliseCritica?.titulo,
				size: parsedAnaliseCritica?.tamanhoArquivoEmMb,
				// type: parsedAnaliseCritica?.tipo,
				linkBy: parsedAnaliseCritica?.caminho,
				file: '',
			},
		};

		return cleanDeep(formatedFiles);
	}, [continuousImprovementData]);

	const votos = data.find(item => item.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP)?.votacoes?.registrosVotacao;

	const caapMembers = useMemo(() => {
		return caapMembersData?.map(item => ({
			id: item.id,
			representacao: item.representacao,
			titular: {
				nome: item.titular.nome,
			},
			suplente: {
				nome: item.suplente.nome,
			},
			voto: votos?.find(voto => voto.id === item.id)?.voto,
		}));
	}, [caapMembersData, votos]);

	const onHandleCreate = async (values: IResultEvaluationForm) => {
		const files = values?.documentos?.map(file => file.file!);

		const initialPayload = {
			idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
			descricao: values.descricao,
			responsavelLancamento: user.nomeCompleto,
			masp: user.masp,
			etapa: values.etapa,
		};

		if (values.faseEnum === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
			const payload = {
				...initialPayload,
				desempenho: values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: values?.naoSeAplica ? -1 : formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
			};

			if (files?.length) {
				await uploadLaunchPhaseMutation(files, {
					onSuccess: async data => {
						await createLaunchPhaseMutation({
							...payload,
							documentos: data,
						});
					},
				});
			} else {
				await createLaunchPhaseMutation(payload);
			}

			return isPhaseSuccessfullyCreated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO) {
			const isDeferred = values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO;

			const payload = {
				...initialPayload,
				desempenho: isDeferred ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: isDeferred ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: isDeferred
					? undefined
					: values?.naoSeAplica
					? -1
					: formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: isDeferred ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
				statusDeferimento: values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO,
			};

			if (files?.length) {
				await uploadValidationPhaseMutation(files, {
					onSuccess: async data => {
						await createValidationPhaseMutation({
							...payload,
							documentos: data,
						});
					},
				});
			} else {
				await createValidationPhaseMutation(payload);
			}

			return isPhaseSuccessfullyCreated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA) {
			const payload = {
				...initialPayload,
				solicitacaoEnum: values.solicitacaoEnum,
				revisarPenalizacao: values.revisarPenalizacao,
			};

			if (files?.length) {
				await uploadAppealFirstInstancePhaseMutation(files, {
					onSuccess: async data => {
						await createAppealFirstInstancePhaseMutation({
							...payload,
							documentos: data,
						});
					},
				});
			} else {
				await createAppealFirstInstancePhaseMutation(payload);
			}

			return isPhaseSuccessfullyCreated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA) {
			const hasResults =
				values.statusDeferimento === RESULT_EVALUATION.DEFERIDO &&
				values.solicitacaoEnum === RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO;

			const payload = {
				...initialPayload,
				desempenho: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: !hasResults
					? undefined
					: values?.naoSeAplica
					? -1
					: formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
				solicitacaoEnum: values.solicitacaoEnum,
				statusDeferimento: values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO,
			};

			if (files?.length) {
				await uploadDecisionFirstInstancePhaseMutation(files, {
					onSuccess: async data => {
						await createDecisionFirstInstancePhaseMutation({
							...payload,
							documentos: data,
						});
					},
				});
			} else {
				await createDecisionFirstInstancePhaseMutation(payload);
			}

			return isPhaseSuccessfullyCreated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA) {
			const payload = {
				...initialPayload,
				solicitacaoEnum: values.solicitacaoEnum,
				revisarPenalizacao: values.revisarPenalizacao,
			};

			if (files?.length) {
				await uploadAppealSecondInstancePhaseMutation(files, {
					onSuccess: async data => {
						await createAppealSecondInstancePhaseMutation({
							...payload,
							documentos: data,
						});
					},
				});
			} else {
				await createAppealSecondInstancePhaseMutation(payload);
			}

			return isPhaseSuccessfullyCreated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.CONTRARRAZOES) {
			if (files?.length) {
				await uploadCounterReasonsPhaseMutation(files, {
					onSuccess: async data => {
						await createCounterReasonsPhaseMutation({
							...initialPayload,
							documentos: data,
						});
					},
				});
			} else {
				await createCounterReasonsPhaseMutation(initialPayload);
			}

			return isPhaseSuccessfullyCreated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
			const hasResults =
				values.statusDeferimento === RESULT_EVALUATION.DEFERIDO &&
				values.solicitacaoEnum === RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO;

			const payload = {
				...initialPayload,
				desempenho: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: !hasResults
					? undefined
					: values?.naoSeAplica
					? -1
					: formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
				statusDeferimento: values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO,
				solicitacaoEnum: values.solicitacaoEnum,
				votacoes:
					values.votacoes?.registrosVotacao?.map(item => ({
						voto: item.voto!,
						representacoes: {
							id: Number(item.id),
							representacao: item.representacao,
						},
					})) || [],
			};

			if (files?.length) {
				await uploadCaapDecisionPhaseMutation(files, {
					onSuccess: async data => {
						await createCaapDecisionPhaseMutation({
							...payload,
							documentos: data,
						});
					},
				});
			} else {
				await createCaapDecisionPhaseMutation(payload);
			}

			return isPhaseSuccessfullyCreated;
		}

		return false;
	};

	const onHandleUpdate = async (values: IResultEvaluationForm) => {
		const defaultFiles = values?.documentos
			?.filter(file => file.linkBy)
			.map(file => ({
				id: Number(file.id),
				titulo: file?.name!,
				path: file?.linkBy!,
				tamanhoArquivo: file?.size!,
				tipoArquivo: file?.type!.replace('.', '').toUpperCase(),
			}));

		const newFiles = values.documentos?.filter(arquivo => !arquivo.linkBy).map(arquivo => arquivo.file!);

		let files: IResultEvaluationDocument[] = [];

		if (defaultFiles?.length) {
			files.push(...defaultFiles);
		}

		const initialPayload = {
			id: values.id,
			idIndicadoresPactoGestao: values.idIndicadoresPactoGestao,
			descricao: values.descricao,
			documentos: files,
			responsavelLancamento: user.nomeCompleto,
			masp: user.masp,
			etapa: values.etapa,
		};

		if (values.faseEnum === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
			const payload = {
				...initialPayload,
				desempenho: values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: values?.naoSeAplica ? -1 : summary?.pesoCalculado,
				resultadoTotal: values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
			};

			if (newFiles?.length) {
				await uploadLaunchPhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateLaunchPhaseMutation({
							...payload,
							documentos: files,
						});
					},
				});
			} else {
				await updateLaunchPhaseMutation(payload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO) {
			const isDeferred = values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO;

			const payload = {
				...initialPayload,
				desempenho: isDeferred ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: isDeferred ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: isDeferred
					? undefined
					: values?.naoSeAplica
					? -1
					: formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: isDeferred ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
				statusDeferimento: values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO,
			};

			if (newFiles?.length) {
				await uploadValidationPhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateValidationPhaseMutation({
							...payload,
							documentos: files,
						});
					},
				});
			} else {
				await updateValidationPhaseMutation(payload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA) {
			const payload = {
				...initialPayload,
				solicitacaoEnum: values.solicitacaoEnum,
				revisarPenalizacao: values.revisarPenalizacao,
			};

			if (newFiles?.length) {
				await uploadAppealFirstInstancePhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateAppealFirstInstancePhaseMutation({
							...payload,
							documentos: files,
						});
					},
				});
			} else {
				await updateAppealFirstInstancePhaseMutation(payload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA) {
			const hasResults =
				values.statusDeferimento === RESULT_EVALUATION.DEFERIDO &&
				values.solicitacaoEnum === RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO;

			const payload = {
				...initialPayload,
				desempenho: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: !hasResults
					? undefined
					: values?.naoSeAplica
					? -1
					: formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
				solicitacaoEnum: values.solicitacaoEnum,
				statusDeferimento: values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO,
			};

			if (newFiles?.length) {
				await uploadDecisionFirstInstancePhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateDecisionFirstInstancePhaseMutation({
							...payload,
							documentos: files,
						});
					},
				});
			} else {
				await updateDecisionFirstInstancePhaseMutation(payload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA) {
			const payload = {
				...initialPayload,
				solicitacaoEnum: values.solicitacaoEnum,
				revisarPenalizacao: values.revisarPenalizacao,
			};

			if (newFiles?.length) {
				await uploadAppealSecondInstancePhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateAppealSecondInstancePhaseMutation({
							...payload,
							documentos: files,
						});
					},
				});
			} else {
				await updateAppealSecondInstancePhaseMutation(payload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.CONTRARRAZOES) {
			if (newFiles?.length) {
				await uploadCounterReasonsPhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateCounterReasonsPhaseMutation({
							...initialPayload,
							documentos: files,
						});
					},
				});
			} else {
				await updateCounterReasonsPhaseMutation(initialPayload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		if (values.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
			const hasResults =
				values.statusDeferimento === RESULT_EVALUATION.DEFERIDO &&
				values.solicitacaoEnum === RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO;

			const payload = {
				...initialPayload,
				desempenho: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.desempenho!),
				pontosObtidos: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.pontosObtidos!),
				pesoCalculado: !hasResults
					? undefined
					: values?.naoSeAplica
					? -1
					: formatFromStringToNumber(values.pesoCalculado!) / TOTAL_PERCENTAGE,
				resultadoTotal: !hasResults ? undefined : values?.naoSeAplica ? -1 : unMaskThousands(values.resultadoTotal!),
				statusDeferimento: values?.statusDeferimento === RESULT_EVALUATION.DEFERIDO,
				solicitacaoEnum: values.solicitacaoEnum,
				votacoes:
					values.votacoes?.registrosVotacao?.map(item => ({
						id: item.idVoto!,
						voto: item.voto!,
						representacoes: {
							id: Number(item.idRepresentacao),
							representacao: item.representacao,
						},
					})) || [],
			};

			if (newFiles?.length) {
				await uploadCaapDecisionPhaseMutation(newFiles, {
					onSuccess: async data => {
						files.push(...data);

						await updateCaapDecisionPhaseMutation({
							...payload,
							documentos: files,
						});
					},
				});
			} else {
				await updateCaapDecisionPhaseMutation(payload);
			}

			return isPhaseSuccessfullyUpdated;
		}

		return false;
	};

	const onHandleDelete = (phase: string, id: string) => {
		if (phase === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
			deleteLaunchPhaseMutation(id);
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO) {
			deleteValidationPhaseMutation(id);
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA) {
			deleteAppealFirstInstancePhaseMutation(id);
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA) {
			deleteDecisionFirstInstancePhaseMutation(id);
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA) {
			deleteAppealSecondInstancePhaseMutation(id);
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.CONTRARRAZOES) {
			deleteCounterReasonsPhaseMutation(id);
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
			deleteCaapDecisionPhaseMutation(id);
			return;
		}
	};

	const onHandleCreateNewPhase = (phase: string) => {
		if (phase === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
			getLaunchPhaseNextMutation();
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO) {
			getValidationPhaseNextMutation();
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA) {
			getAppealFirstInstancePhaseNextMutation();
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA) {
			if (summary?.pesoCalculado! < 0) return onOpenModal();

			getDecisionFirstInstancePhaseNextMutation();
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA) {
			getAppealSecondInstancePhaseNextMutation();
			return;
		}

		if (phase === RESULT_EVALUATION_PHASES.CONTRARRAZOES) {
			getCounterReasonsPhaseNextMutation();
			return;
		}
	};

	const handleNextStep = (values: IVotingRecord[]) => {
		const totalVotesPerOption = formatVotingData(values);
		const defaultData = data.at(-1);

		const newData: IResultEvaluationForm = {
			...defaultData,
			votacaoFinalizada: true,
			votacoes: {
				registrosVotacao: values,
				totalAbstencao: totalVotesPerOption.ABSTENCAO,
				totalAusente: totalVotesPerOption.AUSENTE,
				totalContrario: totalVotesPerOption.CONTRARIO,
				totalFavoravel: totalVotesPerOption.FAVORAVEL,
				totalInabilitado: totalVotesPerOption.INABILITADO,
				totalVotos: values?.length,
			},
		};

		let parsedData = data;

		parsedData?.pop();

		parsedData?.push(newData);

		dataWithVotes = parsedData;
		getCounterReasonsPhaseNextMutation();
	};

	const handleUploadArchive = async (type: FileTypes, doc: IFile) => {
		if (await fileSchema.validate(doc)) {
			setValue(`${type}`, doc);

			const values: IUploadContinuousImprovement = {
				tipoArquivo: CONTINUOUS_IMPROVEMENT_FORM_TO_ENUM[type as keyof typeof CONTINUOUS_IMPROVEMENT_FORM_TO_ENUM],
				arquivo: doc.file,
			};

			uploadContinuousImprovementMutation(values);
		} else {
			setValue(`${type}`, {});
			setError(`${type}`, { type: 'custom' });
		}
	};

	const handleDownloadArchive = (file: IFile) => {
		const payload: IDownloadFileRequest = {
			fileName: file.name!,
			path: file.linkBy!,
		};

		downloadFilesMutate(payload);
	};

	const handleDeleteArchive = (file: IFile) => {
		deteleContinuousImprovementMutation(String(file.id));
	};

	const onCreateRequest = (data: IContinuousImprovementRequest) => {
		createContinuousImprovementMutation(data);
	};

	const onUpdateRequest = (data: IContinuousImprovementRequest) => {
		updateContinuousImprovementMutation(data);
	};

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.wrapper}>
				<PageWrapper title="Registro de Avaliação" />
			</Flex>

			<Flex sx={styles.buttonContainer}>
				{canViewAuditHistory && (
					<Button
						variant="unstyled"
						leftIcon={<RepeatClockIcon />}
						sx={styles.buttonShowModalAudit}
						onClick={onOpenAuditModal}
					>
						Histórico de Alterações
					</Button>
				)}
				<Flex as="form">
					<FileModal
						label="Enviar"
						subLabel="'.pdf', '.docx', '.xlsx' ou '.pptx'"
						acceptValues={ALLOWED_EXTENSIONS}
						onChangeDoc={handleUploadArchive}
						error={errors}
						onDownload={handleDownloadArchive}
						onDelete={handleDeleteArchive}
						control={control}
						defaultValues={archives}
						phase={data.at(-1)?.faseEnum}
						step={summary?.etapa}
					/>
				</Flex>

				<ContinuousImprovementModal
					onCreate={onCreateRequest}
					onUpdate={onUpdateRequest}
					defaultValues={continuousImprovementRequest}
				/>
			</Flex>
			{isSummaryLoading || isGraphicDataLoading || isAllPhasesLoading || isRefetchingAllPhases ? (
				<Flex sx={styles.loadingContainer}>
					<Spinner size="xl" color="#3EA2A2" />
				</Flex>
			) : (
				<Flex sx={styles.content}>
					<Flex sx={styles.graphicContainer}>
						<Flex sx={styles.graphicContent}>
							<ResultsEvaluationIndicatorCard data={card} />
						</Flex>
						<Flex sx={styles.graphicContent}>
							<ResultsEvaluationIndicatorChart {...chart} />
						</Flex>
					</Flex>

					<Flex sx={styles.formContainer}>
						{summary?.etapa === STEPS_ENUM.ETAPA_UM
							? data?.map((item, index) => {
									if (item.faseEnum !== RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
										return (
											<ResultEvaluationForm
												key={index}
												defaultValues={item}
												onDelete={onHandleDelete}
												index={index}
												onCreate={onHandleCreate}
												onUpdate={onHandleUpdate}
												onNewPhase={onHandleCreateNewPhase}
												unitMeasurement={summary.unidadeMedidaEnum}
											/>
										);
									}

									return (
										<React.Fragment key={index}>
											{!item?.id && (
												<ResultEvaluationVotingForm
													defaultValues={{
														...item,
														registrosVotacao: caapMembers,
													}}
													onSubmit={handleNextStep}
													isDisabled={Boolean(item.votacaoFinalizada)}
												/>
											)}
											{(item.votacaoFinalizada || item.id) && (
												<ResultEvaluationForm
													defaultValues={item}
													onDelete={onHandleDelete}
													index={index}
													onCreate={onHandleCreate}
													onUpdate={onHandleUpdate}
													onNewPhase={onHandleCreateNewPhase}
													hasVotingForm={Boolean(item?.votacoes?.registrosVotacao?.length)}
													unitMeasurement={summary.unidadeMedidaEnum}
												/>
											)}
										</React.Fragment>
									);
							  })
							: data?.map((item, index) => (
									<ResultEvaluationForm
										key={index}
										defaultValues={item}
										onDelete={onHandleDelete}
										index={index}
										onCreate={onHandleCreate}
										onUpdate={onHandleUpdate}
										onNewPhase={onHandleCreateNewPhase}
										unitMeasurement={summary?.unidadeMedidaEnum!}
									/>
							  ))}
					</Flex>
				</Flex>
			)}

			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />

			<CustomModal
				icons={[{ type: 'info' }]}
				title="Aviso!"
				body="O indicador está suspenso, por favor contacte a Área técnica."
				isOpen={isOpenModal}
				onClose={onCloseModal}
				actions={[
					{
						label: 'Entendido',
						type: 'primary',
						onClick: onCloseModal,
						datatestid: 'button--confirm',
					},
				]}
			/>

			<CustomModal
				icons={[{ type: 'info' }]}
				title="Aviso!"
				body="O formato de todos os arquivos devem ser: '.pdf', '.docx', '.xlsx' ou '.pptx' de no máximo 10mb."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Entendido',
						type: 'primary',
						onClick: onClose,
						datatestid: 'button--confirm',
					},
				]}
			/>
		</Flex>
	);
};

export default ResultEvaluationScreen;
