/**
 *
 * IndicatorsForm
 *
 */

import CustomCalendar from '../CustomCalendar';
import CustomModal from '../CustomModal';
import ImportIndicatorModal from '../ImportIndicatorModal';
import IndicatorsFormValidationHover from '../IndicatorsFormValidationHover';
import InputMask from '../InputMask';
import InputText from '../InputText';
import InputTextarea from '../InputTextarea';
import PageWrapper from '../PageWrapper';
import PerformanceRangeModal from '../PerformanceRangeModal';
import Prompt from '../Prompt';
import Select from '../Select';
import { useEffect, useMemo, useState } from 'react';
import {
	Box,
	HStack,
	SystemStyleObject,
	useDisclosure,
	Text,
	Button,
	Divider,
	RadioGroup,
	Radio,
	Stack,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	GridItem,
	Flex,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ImportIcon, TableEditIconEdited } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getIndicatorById } from 'services/http/indicators';
import { getPerformanceRange } from 'services/http/performanceRange';
import { getBoardSector, getSector } from 'services/http/sectors';
import { getAllStrategicObjective, getPerspectiveByYear } from 'services/http/strategicObjetive';
import { IResponseIndicatorById, IndicatorFormSteps } from 'types/indicators';
import { IPerformanceRange } from 'types/performanceRange';
import { IPerspective } from 'types/perspective';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { IStepProps } from 'types/stepper';
import { IStrategicObjective } from 'types/strategicObjetive';
import {
	API_DEFAULT_ERROR,
	CELLPHONE_MASK,
	DATA_CONTROL_STEPS,
	ONLY_NUMBERS_REGEX,
	PHONE_MASK,
	POLARITIES,
	POLARITIES_TO_IOPTIONS,
	RESPONSAVELLANCAMENTO,
	RESPONSIBLE_FOR_REALEASE,
	RESULTER_FILTER_STEPS,
	SECTOR_TYPE,
	SEI_CODE_MASK,
	STEPS_ENUM,
	UNIDADEMEDIDA,
	UNIT_MEASUREMENT,
	USER_ROLES,
} from 'utils/constants';
import { getUnitFromSector } from 'utils/getUnitFromSector';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import { getYearsForRange } from 'utils/yearsArray';
import { validatePhone } from 'validations-br';
import * as yup from 'yup';

export interface IIndicatorForm {
	id?: string;
	nome?: string;
	ano?: string | number;
	areaTecnica?: IOption<Partial<ISector>>;
	responsavelLancamento?: IOption;
	objetivo?: string;
	objetivoEstrategico?: IOption;
	perspectiva?: IOption;
	metodologiaCalculo?: string;
	unidadeMedida?: IOption;
	polaridade?: IOption;
	metaCumulativa?: string;
	fonteDados?: string;
	faixasDesempenho?: IOption<IPerformanceRange>;
	fonteComprovacao?: string;
	setor?: IOption;
	nomeResponsavel?: string;
	email?: string;
	telefone?: string;
	sei?: string | null;
	etapaEnum?: string;
}

interface IndicatorsFormProps extends IStepProps<IIndicatorForm, IndicatorFormSteps> {
	isEditing?: boolean;
	isSubmitingForm?: boolean;
	validationDate?: string | null;
	onHandleValidate?: (values: IndicatorFormSteps, changed: boolean) => void;
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type Params = {
	etapa: keyof typeof DATA_CONTROL_STEPS;
};

const IndicatorsForm = ({
	isEditing,
	defaultValues,
	isSubmitingForm = false,
	onSubmit,
	nextStep,
	validationDate,
	onHandleValidate,
}: IndicatorsFormProps) => {
	const { addToast } = useCustomToast();
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		containerForm: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: '#F9FAFC',
			justifyContent: { base: 'center', md: 'flex-start' },
			pt: '2.125rem',
			pb: '1.25rem',
			pl: '2rem',
			pr: '3.563rem',
			position: 'relative',
		},
		button: {
			alignSelf: 'flex-end',
			px: '1rem',
			m: '0.125rem 0 0.875rem',
		},
		content: {
			width: '100%',
		},

		fieldsContent: {
			width: '100%',
			gap: { base: '2rem', md: '2rem', lg: '2rem' },
			flexDirection: { base: 'column', md: 'column', lg: 'row' },
			mt: '1.5rem',
			alignItems: 'flex-end',
		},

		ano: {
			width: '100%',
			maxWidth: { base: '100%' },
			mt: '20px',
		},
		technicalArea: {
			width: { base: '100%', md: '100%', xl: '22rem', '2xl': '25rem' },
			maxWidth: { base: '100%', md: '100%', xl: '22rem', '2xl': '25rem' },
			mt: { base: '20px', lg: 0 },
		},
		postingManager: {
			width: { base: '100%', md: '100%', xl: '18rem' },
			maxWidth: { base: '100%', md: '100%', xl: '18rem' },
		},
		indicatoroObjective: {
			width: '100%',
			maxWidth: { base: '100%', md: '100%', lg: '39.5rem' },
		},
		indicatoroObjectiveTextArea: {
			height: '8.675rem',
		},
		fieldsSelect: {
			width: '100%',
		},
		strategicObjective: {
			mt: '0.5rem',
		},
		perspective: {
			width: '100%',
		},
		calculationMethodology: {
			width: '100%',
			maxWidth: { base: '100%', md: '100%', lg: '100%' },
			mb: '1rem',
		},
		divider: {
			mt: '1.5rem',
		},
		unitMeasure: {
			width: { base: '100%', md: '100%', xl: '18rem' },
			maxWidth: { base: '100%', md: '100%', xl: '18rem' },
		},
		polarity: {
			width: { base: '100%', md: '100%', xl: '16rem' },
			maxWidth: { base: '100%', md: '100%', xl: '16rem' },
		},
		cumulativeTargetBox: {
			width: { base: '100%', md: '100%', xl: '9rem' },
			maxWidth: { base: '100%', md: '100%', xl: '9rem' },
		},
		dataSource: {
			width: { base: '100%', md: '100%', xl: '25rem' },
			maxWidth: { base: '100%', md: '100%', xl: '25rem' },
		},
		performanceRange: {
			width: { base: '100%', md: '100%', xl: '27rem' },
			maxWidth: { base: '100%', md: '100%', xl: '27rem' },
		},
		proof: {
			width: '100%',
			maxWidth: { base: '100%', md: '100%', lg: '100%' },
		},
		technicalReferenceContact: {
			width: '100%',
			mt: '1.5rem',
			mb: '2rem',
		},

		formButtonsBox: {
			width: '100%',
			mt: '3rem',
		},
		formSaveButton: {
			width: '100%',
			maxWidth: { base: '100%', md: '100%', lg: '20.188rem' },
			mr: '2.5rem',
			mb: '0.75rem',
		},
		formCancelButton: {
			width: '100%',
			maxWidth: { base: '100%', md: '100%', lg: '20.188rem' },
			mb: '0.75rem',
		},
		rangeModalButton: {
			width: '100%',
			maxWidth: { base: '100%', md: '100%', lg: '20.188rem' },
		},

		InputText: {
			fontSize: '1rem',
		},
		InputTextArea: {
			height: '12.125rem',
		},
		isEditingIcon: {
			ml: '0.5rem',
		},
		fieldsContenGrid: {
			gap: '2rem',
			rowGap: '1.5rem',
			mt: '1rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
				xl: 'repeat(4, 1fr)',
			},
			height: 'fit-content',
		},
		fieldsContenGrid2: {
			gap: '2rem',
			rowGap: '1.5rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
			height: 'fit-content',
			mb: '0.5rem',
		},
		fieldsContenGrid3: {
			gap: '2rem',
			rowGap: '1.5rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
				xl: 'repeat(3, 1fr)',
			},
			height: 'fit-content',
			mt: '1rem',
		},
	};

	const {
		session: { user },
	} = useSession();

	const userUnit = user.unidade;
	const technicalAreaUnit = getUnitFromSector(defaultValues?.areaTecnica?.label);
	const representativeUnits = user?.unidadesRepresentantes?.map(unidade => getUnitFromSector(unidade?.nomePactuado));

	const presidentPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const secondStepPermission = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
		USER_ROLES.REPRESENTANTE,
	]);

	const technicalAreaUnitIqualsUserUnit = technicalAreaUnit === userUnit;

	const representativeUnitsHasTechnicalAreaUnit = representativeUnits.some(unit => unit === technicalAreaUnit);

	const isTechnicalArea = technicalAreaUnitIqualsUserUnit || representativeUnitsHasTechnicalAreaUnit;

	const canHandleSecondStep = secondStepPermission && isTechnicalArea;

	const hasPermission = defaultValues?.etapaEnum === STEPS_ENUM.ETAPA_UM ? firstStepPermission : canHandleSecondStep;
	const hasPermissionToValidate =
		defaultValues?.etapaEnum === STEPS_ENUM.ETAPA_UM ? presidentPermission : firstStepPermission;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isImportIndicatorModalOpen,
		onOpen: onImportIndicatorModalOpen,
		onClose: onImportIndicatorModalClose,
	} = useDisclosure();

	const navigate = useNavigate();

	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const validdatePhoneNumber = (phone?: string) => {
		if (!phone) return false;

		const numbers = phone.replaceAll(ONLY_NUMBERS_REGEX, '');

		if (numbers.length < 9) return false;

		return validatePhone(phone);
	};

	const schema = yup.object().shape({
		nome: yup.string().required('Este campo é obrigatório.'),
		ano: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		areaTecnica: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		responsavelLancamento: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		objetivo: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.'),
		objetivoEstrategico: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		perspectiva: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		faixasDesempenho: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		metodologiaCalculo: yup.string().required('Este campo é obrigatório.'),
		unidadeMedida: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		polaridade: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		metaCumulativa: yup.string().required('Este campo é obrigatório.'),
		fonteDados: yup.string().required('Este campo é obrigatório.'),
		fonteComprovacao: yup.string().required('Este campo é obrigatório.'),
		setor: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		nomeResponsavel: yup.string().required('Este campo é obrigatório'),
		email: yup.string().email('E-mail inválido').required('Este campo é obrigatório'),
		sei: yup
			.string()
			.test('validSei', 'Campo S.E.I. inválido.', value => {
				const seiMinLength = 19;
				const seiSize = value?.replace(/\D/g, '')?.length;
				return !seiSize || seiSize === seiMinLength;
			})
			.nullable(),
		telefone: yup
			.string()
			.required('Este campo é obrigatório.')
			.test('noveDigitos', 'Número de telefone inválido', value => validdatePhoneNumber(value)),
	});

	const { etapa } = useParams<Params>();

	const {
		control,
		handleSubmit,
		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
		register,
		getValues,
		resetField,
		watch,
		reset,
		setValue,
	} = useForm<IndicatorFormSteps>({
		resolver: yupResolver(schema),
		defaultValues,
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const stepsEnum = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION, SECTOR_TYPE.BOARD_TYPE],
	};

	const selectedPerspective = watch('perspectiva');
	const selectedStrategicObjective = watch('objetivoEstrategico');
	const selectedYear = watch('ano');
	const selectedPerformance = watch('faixasDesempenho');
	const { isOpen: isModalRangeOpen, onOpen: onModalRangeOpen, onClose: onModalRangeClose } = useDisclosure();

	const handlePerformanceChange = (newValue: IOption<IPerformanceRange>) => {
		setValue('faixasDesempenho', newValue);
	};

	const { data: technicalArea, isLoading: isLoadingTechnicalArea } = useQuery<
		ISector[],
		AxiosError<ResponseErrors>,
		ISector[]
	>(
		['technical-area'],
		() =>
			getSector(
				!isEditing
					? stepsEnum[DATA_CONTROL_STEPS[etapa!] as keyof typeof stepsEnum]
					: stepsEnum[defaultValues?.etapaEnum! as keyof typeof stepsEnum],
				stringifyCurrentUserUnits(user),
			),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: isEditing && !!defaultValues?.etapaEnum,
		},
	);

	const { data: sector, isLoading: isLoadingSector } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['responsible-sector', getValues('areaTecnica.value')],
		() => getBoardSector(String(getValues('areaTecnica.value'))),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),

			enabled: !!getValues('areaTecnica.value'),
		},
	);

	const { data: performanceRange } = useQuery<IPerformanceRange[], AxiosError<ResponseErrors>, IPerformanceRange[]>(
		['performance-range'],
		() => getPerformanceRange({ mostrarFaixasInativas: false }),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const { data: strategicObjetives } = useQuery<IStrategicObjective[], AxiosError<ResponseErrors>>(
		['strategic-objetives', selectedYear],
		() => getAllStrategicObjective({ ano: String(selectedYear) }),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!selectedYear,
		},
	);

	const { data: perspective } = useQuery<IPerspective[], AxiosError<ResponseErrors>>(
		['perspectives-by-year', selectedYear],
		() => getPerspectiveByYear(String(selectedYear)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!selectedYear,
		},
	);

	const handleGetIndicator = (indicatorId: string) => {
		getIndicatorByIdMutation(indicatorId);
	};

	const { mutate: getIndicatorByIdMutation } = useMutation<IResponseIndicatorById, AxiosError<ResponseErrors>, string>(
		['indicadorById'],
		indicatorId => getIndicatorById(indicatorId),
		{
			onSuccess: data => {
				const formData: Optional<IIndicatorForm, 'areaTecnica' | 'setor'> = {
					nome: data?.indicador?.nome,
					responsavelLancamento: {
						value: String(data?.indicador?.responsavelLancamento),
						label: RESPONSAVELLANCAMENTO[data?.indicador?.responsavelLancamento as keyof typeof RESPONSAVELLANCAMENTO],
					},
					unidadeMedida: {
						value: String(data?.indicador?.unidadeMedida),
						label: UNIDADEMEDIDA[data?.indicador?.unidadeMedida as keyof typeof UNIDADEMEDIDA],
					},
					polaridade: {
						value: String(data?.indicador?.polaridade),
						label: POLARITIES[data?.indicador?.polaridade as keyof typeof POLARITIES],
					},
					metaCumulativa: data?.indicador?.metaCumulativa === true ? 'Sim' : 'Não',
					faixasDesempenho: {
						value: Number(data?.indicador?.conjunto?.id),
						label: String(data?.indicador?.conjunto?.nome),
						data: data?.indicador?.conjunto,
					},
					objetivo: data?.indicador?.objetivo,
					metodologiaCalculo: data?.indicador?.metodologiaCalculo,
					fonteDados: data?.indicador?.fonteDados,
					fonteComprovacao: data?.indicador?.fonteComprovacao,
					nomeResponsavel: data?.indicador?.nomeResponsavel,
					email: data?.indicador?.email,
					telefone: data?.indicador?.telefone,
					sei: data?.indicador?.sei || '',
					etapaEnum: data?.indicador?.etapaEnum,
				};

				if (data?.indicador?.etapaEnum !== DATA_CONTROL_STEPS[String(etapa) as keyof typeof DATA_CONTROL_STEPS]) {
					delete formData.areaTecnica;
					delete formData.setor;
				}

				reset(formData);
			},
			onError: ({ response }) => {
				addToast({
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
					type: 'error',
				});
			},
		},
	);

	const handleValidateIndicator = () => {
		onHandleValidate?.(getValues(), isDirty);
	};

	const filteredObjectives = useMemo(() => {
		const objectivesByPerspective = selectedPerspective
			? strategicObjetives?.filter(objetive => objetive.perspectiva.id === selectedPerspective.value)
			: [];

		const loadDefaultObjectives = !selectedPerspective?.value && !objectivesByPerspective?.length;

		const unorderedObjectives = loadDefaultObjectives ? strategicObjetives : objectivesByPerspective;

		const orderedObjectives = unorderedObjectives?.sort((a, b) =>
			String(a?.objetivo)?.localeCompare(String(b?.objetivo), undefined, { numeric: true, sensitivity: 'base' }),
		);

		return orderedObjectives;
	}, [selectedPerspective, strategicObjetives]);

	const filteredPerspectives = useMemo(() => {
		const objective = selectedStrategicObjective?.value
			? strategicObjetives?.find(obj => obj.id === selectedStrategicObjective?.value)
			: undefined;

		const perspectiveByObjective = objective
			? perspective?.filter(perspectiva => perspectiva.id === objective?.perspectiva.id)
			: [];

		const loadDefaultPerspectives = !selectedStrategicObjective?.value && !perspectiveByObjective?.length;

		return loadDefaultPerspectives ? perspective : perspectiveByObjective;
	}, [perspective, selectedStrategicObjective?.value, strategicObjetives]);

	const orderedPerformanceRange = useMemo(
		() =>
			performanceRange?.sort((a, b) =>
				String(a?.nome)?.localeCompare(String(b?.nome), undefined, { numeric: true, sensitivity: 'base' }),
			),
		[performanceRange],
	);

	const orderedSectors = useMemo(() => {
		const selectedTechnicalArea = technicalArea?.find(data => data?.idSetor === getValues('areaTecnica')?.value);
		const sectorsList =
			sector
				?.sort((a, b) =>
					String(a?.idOrdem)?.localeCompare(String(b?.idOrdem), undefined, { numeric: true, sensitivity: 'base' }),
				)
				?.filter(sector => sector?.idSetor !== selectedTechnicalArea?.idSetor) || [];

		return selectedTechnicalArea ? [selectedTechnicalArea, ...sectorsList] : sectorsList;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sector, technicalArea]);

	const { yearMin: yearMinDate, yearMax: yearMaxDate } = getYearsForRange();

	const handleOnSubmit = (values: IIndicatorForm) => {
		onSubmit(values);
		nextStep?.();
	};

	useEffect(() => reset(defaultValues), [defaultValues, reset]);

	const validSector = useMemo(() => {
		return !!getValues('areaTecnica.value');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('areaTecnica'), getValues]);

	const clearSectorField = () => {
		resetField('setor', {
			defaultValue: '',
		});
	};

	const clearFieldsByYear = () => {
		resetField('objetivoEstrategico', {
			defaultValue: '',
		});

		resetField('perspectiva', {
			defaultValue: '',
		});

		setValue('pactuados', undefined);

		setValue('tags', undefined);
	};

	const maskBuilder = (value?: string) => {
		if (!value) return PHONE_MASK;
		return value?.length >= 6 && value?.at(5) === '9' ? CELLPHONE_MASK : PHONE_MASK;
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.indicators);
		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	const handleModalRangeOpen = () => {
		onModalRangeOpen();
	};

	const cantViewFirstStep = defaultValues?.etapaEnum === STEPS_ENUM.ETAPA_UM && !firstStepPermission;
	const cantViewSecondStep =
		defaultValues?.etapaEnum === STEPS_ENUM.ETAPA_DOIS && !canHandleSecondStep && !firstStepPermission;

	if (isEditing && (cantViewFirstStep || cantViewSecondStep)) {
		return <Navigate to={ROUTES.home} replace />;
	}

	return (
		<Flex sx={styles?.container}>
			<Box marginBottom={isEditing ? '60px' : '4px'}>
				<PageWrapper
					title={
						isEditing
							? `Editar Indicadores (${
									RESULTER_FILTER_STEPS[defaultValues?.etapaEnum as keyof typeof RESULTER_FILTER_STEPS]
							  }) `
							: `Cadastrar Indicadores (${etapa === 'etapa-1' ? '1' : '2'}ª Etapa)`
					}
				/>
			</Box>
			{!isEditing && (
				<Button
					leftIcon={<ImportIcon color="white" />}
					sx={styles.button}
					onClick={() => onImportIndicatorModalOpen()}
					data-testid="button--createIndicatorsI"
				>
					Importar um Indicador
				</Button>
			)}

			<Box sx={styles?.containerForm}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(handleOnSubmit)}>
					<GridItem>
						<InputText
							label="Nome do indicador"
							isEditing={isEditing && !!dirtyFields?.nome}
							maxLength={150}
							register={register}
							name="nome"
							placeholder="Digite o nome do indicador"
							errorMessage={errors?.nome?.message}
							data-testid="input--nome"
							type="text"
							sx={styles?.InputText}
							required
						/>
					</GridItem>
					<Grid sx={styles.fieldsContenGrid}>
						<GridItem sx={styles?.ano}>
							<CustomCalendar
								label="Ano"
								placeholder="Selecione o ano"
								fieldName="ano"
								dataTestId="input--year"
								errorMessage={!selectedYear ? errors.ano?.message : ''}
								setValue={(_field, data) => {
									clearFieldsByYear();
									setValue('ano', data);
								}}
								minDate={new Date(yearMinDate, 0, 1)}
								maxDate={new Date(yearMaxDate, 0, 1)}
								control={control}
								isEditing={isEditing && !!dirtyFields?.ano}
								required
								defaultValue={defaultValues?.ano}
								isDisabled={isEditing}
							/>
						</GridItem>

						<GridItem sx={styles.technicalArea}>
							<Controller
								name="areaTecnica"
								control={control}
								render={({ field }) => (
									<Select
										label="Área técnica"
										isEditing={isEditing && !!dirtyFields?.areaTecnica}
										options={parsedOptionArray(technicalArea, 'nome', 'idSetor')}
										placeholder="Selecione a área técnica"
										dataTestId="input--areaTecnica"
										value={field?.value}
										onChange={value => {
											field?.onChange(value);
											clearSectorField();
										}}
										errorMessage={errors?.areaTecnica?.message}
										isLoading={isLoadingTechnicalArea}
										required
									/>
								)}
							/>
						</GridItem>

						<GridItem sx={styles.postingManager}>
							<Controller
								name="responsavelLancamento"
								control={control}
								render={({ field }) => (
									<Select
										isDisabled={isEditing}
										label="Responsável pelo lançamento"
										options={RESPONSIBLE_FOR_REALEASE}
										placeholder="Selecione o responsável"
										dataTestId="input--responsavelLancamento"
										value={field?.value}
										onChange={field?.onChange}
										errorMessage={errors?.responsavelLancamento?.message}
										required
									/>
								)}
							/>
						</GridItem>

						{hasPermission && isEditing && (
							<IndicatorsFormValidationHover
								onValidationCheck={handleValidateIndicator}
								validationDate={validationDate}
								canValidateManagementPact={hasPermissionToValidate}
								data-testId="button--validate"
							/>
						)}
					</Grid>

					<HStack sx={styles?.fieldsContent}>
						<Box sx={styles?.indicatoroObjective}>
							<InputTextarea
								label="Objetivo"
								isEditing={isEditing && !!dirtyFields?.objetivo}
								maxLength={1000}
								register={register}
								placeholder="Digite o objetivo"
								name="objetivo"
								data-testid="input--objetivo"
								sx={styles?.indicatoroObjectiveTextArea}
								errorMessage={errors?.objetivo?.message}
								resize={'none'}
								required
							/>
						</Box>

						<Box sx={styles?.fieldsSelect}>
							<Box sx={styles?.perspective}>
								<Controller
									name="perspectiva"
									control={control}
									render={({ field }) => (
										<Select
											label="Perspectiva"
											isEditing={isEditing && !!dirtyFields?.perspectiva}
											isClearable
											options={parsedOptionArray(filteredPerspectives, 'perspectiva', 'id')}
											placeholder="Selecione a perspectiva"
											dataTestId="input--perspectiva"
											value={field?.value}
											onChange={field?.onChange}
											errorMessage={errors?.perspectiva?.message}
											isDisabled={!selectedYear}
											required
										/>
									)}
								/>
							</Box>

							<Box sx={styles?.strategicObjective}>
								<Controller
									name="objetivoEstrategico"
									control={control}
									render={({ field }) => (
										<Select
											label="Objetivo estratégico"
											isEditing={isEditing && !!dirtyFields?.objetivoEstrategico}
											isClearable
											options={parsedOptionArray(filteredObjectives, 'objetivo', 'id')}
											placeholder="Selecione o objetivo estratégico"
											dataTestId="input--objetivoEstrategico"
											value={field?.value}
											onChange={field?.onChange}
											errorMessage={errors?.objetivoEstrategico?.message}
											isDisabled={!selectedYear}
											required
										/>
									)}
								/>
							</Box>
						</Box>
					</HStack>

					<HStack sx={styles?.fieldsContent}>
						<Box sx={styles?.calculationMethodology}>
							<InputTextarea
								sx={styles.InputTextArea}
								label="Metodologia de cálculo"
								isEditing={isEditing && !!dirtyFields?.metodologiaCalculo}
								maxLength={3000}
								register={register}
								data-testid="input--metodologiaCalculo"
								placeholder="Digite a metodologia de cálculo"
								name="metodologiaCalculo"
								errorMessage={errors?.metodologiaCalculo?.message}
								resize={'none'}
								required
							/>
						</Box>
					</HStack>

					<Grid sx={styles.fieldsContenGrid}>
						<GridItem sx={styles.unitMeasure}>
							<Controller
								name="unidadeMedida"
								control={control}
								render={({ field }) => (
									<Select
										label="Unidade de medida"
										isEditing={isEditing && !!dirtyFields?.unidadeMedida}
										options={UNIT_MEASUREMENT}
										placeholder="Selecione a unidade de medida"
										dataTestId="input--unidadeMedida"
										value={field?.value}
										onChange={field?.onChange}
										errorMessage={errors?.unidadeMedida?.message}
										required
									/>
								)}
							/>
						</GridItem>

						<GridItem sx={styles.polarity}>
							<Controller
								name="polaridade"
								control={control}
								render={({ field }) => (
									<Select
										label="Polaridade"
										isEditing={isEditing && !!dirtyFields?.polaridade}
										options={POLARITIES_TO_IOPTIONS}
										placeholder="Selecione a polaridade"
										dataTestId="input--polaridade"
										value={field?.value}
										onChange={field?.onChange}
										errorMessage={errors?.polaridade?.message}
										required
									/>
								)}
							/>
						</GridItem>

						<GridItem sx={styles?.cumulativeTargetBox}>
							<Controller
								name="metaCumulativa"
								control={control}
								render={({ field }) => (
									<Stack direction="row">
										<FormControl isInvalid={!!errors?.metaCumulativa?.message}>
											<FormLabel display="flex" alignItems="flex-start" marginBottom={'1.5rem'}>
												Meta cumulativa
												{isEditing && !!dirtyFields?.metaCumulativa && (
													<Box sx={styles?.isEditingIcon}>
														<TableEditIconEdited />
													</Box>
												)}
											</FormLabel>

											<RadioGroup onChange={field?.onChange} value={field?.value} name="metaCumulativa" size="md">
												<Stack direction="row" spacing="24px">
													<Radio value="Sim" data-testid="radio--metaCumulativa-yes">
														Sim
													</Radio>
													<Radio value="Não" data-testid="radio--metaCumulativa-no">
														Não
													</Radio>
												</Stack>
											</RadioGroup>

											<FormErrorMessage data-testid="text--error">{errors?.metaCumulativa?.message}</FormErrorMessage>
										</FormControl>
									</Stack>
								)}
							/>
						</GridItem>

						<GridItem sx={styles.dataSource}>
							<InputText
								label="Fonte de dados"
								isEditing={isEditing && !!dirtyFields?.fonteDados}
								maxLength={300}
								register={register}
								placeholder="Digite a fonte de dados"
								name="fonteDados"
								data-testid="input--fonteDados"
								errorMessage={errors?.fonteDados?.message}
								sx={styles?.InputText}
								required
							/>
						</GridItem>
					</Grid>

					<Divider sx={styles?.divider} />

					<HStack sx={styles?.fieldsContent}>
						<Box sx={styles?.performanceRange}>
							<Controller
								name="faixasDesempenho"
								control={control}
								render={({ field }) => (
									<Select
										label="Faixa de desempenho"
										isEditing={isEditing && !!dirtyFields?.faixasDesempenho}
										options={parsedOptionArray(orderedPerformanceRange, 'nome', 'id')}
										placeholder="Digite a faixa de desempenho"
										dataTestId="input--faixaDesempenho"
										value={field?.value}
										onChange={values => {
											field?.onChange(values);
											handlePerformanceChange(values as IOption<IPerformanceRange>);
										}}
										errorMessage={errors?.faixasDesempenho?.message}
										required
									/>
								)}
							/>
						</Box>
						<Button
							onClick={handleModalRangeOpen}
							variant="secondary"
							sx={styles?.rangeModalButton}
							isDisabled={!selectedPerformance}
						>
							Visualizar Faixa
						</Button>
					</HStack>

					<HStack sx={styles?.fieldsContent}>
						<Box sx={styles?.proof}>
							<InputText
								label="Comprovação"
								isEditing={isEditing && !!dirtyFields?.fonteComprovacao}
								maxLength={150}
								register={register}
								placeholder="Digite a fonte de comprovação"
								name="fonteComprovacao"
								data-testid="input--fonteComprovacao"
								errorMessage={errors?.fonteComprovacao?.message}
								sx={styles?.InputText}
								required
							/>
						</Box>
					</HStack>

					<Divider sx={styles?.divider} />

					<Box sx={styles?.technicalReferenceContact}>
						<Text>Contato de referência técnica</Text>
					</Box>

					<Grid sx={styles.fieldsContenGrid2}>
						<GridItem>
							<Controller
								name="setor"
								control={control}
								render={({ field }) => (
									<Select
										label="Setor"
										isEditing={isEditing && !!dirtyFields?.setor}
										options={parsedOptionArray(orderedSectors, 'nome', 'idSetor')}
										placeholder="Selecione o setor"
										dataTestId="input--setor"
										value={field?.value}
										onChange={field?.onChange}
										errorMessage={errors?.setor?.message}
										isDisabled={!validSector}
										isLoading={isLoadingTechnicalArea || isLoadingSector}
										required
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<InputText
								label="Nome do responsável"
								isEditing={isEditing && !!dirtyFields?.nomeResponsavel}
								maxLength={100}
								register={register}
								placeholder="Digite o nome"
								name="nomeResponsavel"
								data-testid="input--nomeResponsavel"
								errorMessage={errors?.nomeResponsavel?.message}
								sx={styles?.InputText}
								required
							/>
						</GridItem>
					</Grid>
					<Grid sx={styles.fieldsContenGrid3}>
						<GridItem>
							<InputText
								label="E-mail"
								isEditing={isEditing && !!dirtyFields?.email}
								maxLength={150}
								register={register}
								placeholder="Digite o e-mail"
								name="email"
								data-testid="input--email"
								errorMessage={errors?.email?.message}
								sx={styles?.InputText}
								required
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="telefone"
								control={control}
								render={({ field }) => (
									<InputMask
										label="Telefone"
										isEditing={isEditing && !!dirtyFields?.telefone}
										mask={maskBuilder(field.value)}
										value={field?.value || ''}
										onChange={field?.onChange}
										name="telefone"
										data-testid="input--telefone"
										placeholder="Digite o telefone"
										errorMessage={errors?.telefone?.message}
										required
									/>
								)}
							/>
						</GridItem>

						<GridItem>
							<Controller
								name="sei"
								control={control}
								render={({ field }) => (
									<InputMask
										label="S.E.I."
										isEditing={isEditing && !!dirtyFields?.sei}
										mask={SEI_CODE_MASK}
										value={field?.value || ''}
										onChange={field?.onChange}
										name="sei"
										placeholder="Digite o S.E.I."
										data-testid="input--sei"
										errorMessage={errors?.sei?.message}
									/>
								)}
							/>
						</GridItem>
					</Grid>

					<HStack sx={styles?.fieldsContent}>
						<Box sx={styles?.formButtonsBox}>
							<Button
								variant="primary"
								sx={styles?.formSaveButton}
								isLoading={isSubmitingForm}
								type="submit"
								data-testid="button--submit"
							>
								Compartilhar
							</Button>

							<Button
								variant="secondary"
								sx={styles?.formCancelButton}
								data-testid="button--cancel"
								onClick={handleOpenModal}
							>
								Cancelar
							</Button>
						</Box>
					</HStack>

					<CustomModal
						icons={[{ type: 'error' }]}
						title="Você realmente quer cancelar?"
						body="Ao cancelar os campos anteriormente preenchidos serão perdidos."
						isOpen={isOpen}
						onClose={onClose}
						actions={[
							{
								label: 'Quero Cancelar',
								type: 'cancel',
								onClick: handleGoBack,
								datatestid: 'button--confirm',
							},
							{
								label: 'Voltar',
								type: 'secondary',
								onClick: handleCloseModal,
								datatestid: 'button--cancel',
							},
						]}
					/>
					<ImportIndicatorModal
						isOpen={isImportIndicatorModalOpen}
						onClose={onImportIndicatorModalClose}
						getIndicator={value => handleGetIndicator(value)}
					/>

					<PerformanceRangeModal
						onClose={onModalRangeClose}
						isOpen={isModalRangeOpen}
						performanceSelected={selectedPerformance!}
						options={parsedOptionArray(performanceRange, 'nome', 'id')}
						onPerformanceChange={handlePerformanceChange}
					/>

					<Prompt when={showPrompt} />
				</Box>
			</Box>
		</Flex>
	);
};

export default IndicatorsForm;
