/**
 *
 * StrategicPlanningForm
 *
 */

import CustomCalendar from '../CustomCalendar';
import CustomModal from '../CustomModal';
import InputText from '../InputText';
import InputTextarea from '../InputTextarea';
import Prompt from '../Prompt';
import { useState, useMemo, useEffect } from 'react';
import { Box, Button, Divider, Flex, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { XIcon } from 'assets/icons';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Perspective, StrategicPlanning } from 'types/strategicPlanning';
import { getYearsForRange } from 'utils/yearsArray';
import * as yup from 'yup';

interface StrategicPlanningFormProps {
	onSubmit: (data: StrategicPlanning) => void;
	defaultValues?: StrategicPlanning;
	isEditing?: boolean;
	isLoadingSubmit?: boolean;
}

const StrategicPlanningForm = ({
	defaultValues,
	isLoadingSubmit = false,
	onSubmit,
	isEditing = false,
}: StrategicPlanningFormProps) => {
	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [perspectivesDeleted, setPerspectiveDeleted] = useState<Perspective[]>([]);
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const schema = yup.object().shape({
		nome: yup.string().required('Este campo é obrigatório.').max(150, 'Máximo de 150 caracteres.'),
		anoInicial: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		anoFinal: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		perspectivas: yup.array().of(
			yup.object().shape({
				perspectiva: yup.string().required('Este campo é obrigatório.').max(50, 'Máximo de 50 caracteres.'),
			}),
		),
		missao: yup.string().required('Este campo é obrigatório.').max(360, 'Máximo de 360 caracteres.'),
		visao: yup.string().required('Este campo é obrigatório.').max(360, 'Máximo de 360 caracteres.'),
		valor: yup.string().required('Este campo é obrigatório.').max(360, 'Máximo de 360 caracteres.'),
	});

	const defaultValuesPerspectives = useMemo(() => {
		return defaultValues?.perspectivas?.length
			? defaultValues?.perspectivas?.map(perspective => ({ ...perspective, status: 'PUT' } as Perspective))
			: [{ perspectiva: '', status: 'POST' } as Perspective];
	}, [defaultValues]);

	const {
		control,
		handleSubmit,
		register,
		watch,
		setValue,

		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
	} = useForm<StrategicPlanning>({
		resolver: yupResolver(schema),
		defaultValues: {
			...defaultValues,
			perspectivas: defaultValuesPerspectives,
		},
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const styles: Record<string, SystemStyleObject> = {
		container: {
			bgColor: 'gray.80',
			border: '1px solid',
			borderColor: 'gray.95',
			borderRadius: '4px',
			p: {
				base: '2.5rem',
				md: '1.625rem 4rem 2.5rem 2rem',
				lg: '1.625rem 6rem 2.5rem 2rem',
				'2xl': '1.625rem 13.5rem 2.5rem 2rem',
			},
		},
		calendarContainer: {
			mb: '1.438rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			gap: {
				base: '2rem',
				lg: '3rem',
			},
		},
		calendarContent: {
			w: '100%',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			maxW: {
				base: '100%',
				lg: '24.25rem',
			},
			gap: {
				base: '4.5rem',
				lg: '3rem',
			},
			pt: errors.anoFinal?.message ? '2.15rem' : '1.35rem',

			mb: {
				base: '1.438rem',
				lg: '0',
			},
		},
		perspectiveContainer: {
			flexDir: 'column',
		},
		perspectiveDividerContainer: {
			m: '1rem 1.875rem 0.5rem 1.875rem',
		},
		perspectiveIconContainer: {
			cursor: 'pointer',
			justifyContent: 'end',
			w: '100%',
			mb: '0.5rem',
		},
		perspectiveButton: {
			px: '5.388rem',
			lineHeight: '0',
			alignSelf: 'center',
			my: '1.438rem',
			w: {
				base: '100%',
				lg: 'fit-content',
			},
			h: {
				base: '3.25rem',
				lg: '2.75rem',
			},
		},
		dividerContainer: {
			m: '0rem 3.438rem 0.938rem 3.5rem',
		},
		textAreaContainer: {
			gap: '2.5rem',
			mb: '2.063rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		textarea: {
			resize: 'none',
		},
		footer: {
			mt: '2.688rem',
			gap: '1.438rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		footerButton: {
			px: '5.375rem',
			lineHeight: '0',
			h: {
				base: '3.25rem',
				lg: '2.75rem',
			},
		},
		input: {
			w: '100%',
			h: {
				base: '3.25rem',
				lg: '2.75rem',
			},
			fontSize: '1rem',
		},
	};

	const { fields, append, remove } = useFieldArray({
		name: 'perspectivas',
		control,
		keyName: 'code',
	});

	const handleAddPerspective = () => append({ perspectiva: '', status: 'POST' });

	const handleDeletePerspective = (perspective: Perspective, index: number) => {
		if (perspective.status === 'PUT') setPerspectiveDeleted(prev => [...prev, { ...perspective, status: 'DELETE' }]);

		remove(index);
	};

	const { yearMin: startYearMinDate, yearMax: startYearMaxDate } = getYearsForRange();
	const [anoFinalDefaultValue, setAnoFinalDefaultValue] = useState('');

	const startYearToFilter = watch('anoInicial');
	const finalYear = watch('anoFinal');

	const endYearMinDate = Number(startYearToFilter) + 1 || startYearMinDate;
	const endYearMaxDate = endYearMinDate + 5;
	const allowedYear = Number(finalYear) <= endYearMaxDate;

	const handleSubmitForm = (data: StrategicPlanning) => {
		if (isEditing) {
			onSubmit({
				...data,
				perspectivas: [...data.perspectivas!, ...perspectivesDeleted],
			});
			return;
		}
		onSubmit(data);
	};

	useEffect(() => {
		if (!startYearToFilter) {
			setValue('anoFinal', '');
			setAnoFinalDefaultValue('');
		}

		if (startYearToFilter && finalYear <= startYearToFilter && allowedYear) {
			setValue('anoFinal', endYearMinDate);
			setAnoFinalDefaultValue(String(endYearMinDate));
		}

		if (startYearToFilter && !allowedYear) {
			setValue('anoFinal', endYearMinDate);
			setAnoFinalDefaultValue(String(endYearMinDate));
		}
	}, [startYearToFilter, setValue, endYearMinDate, finalYear, allowedYear]);

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.strategicPlanning);

		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	return (
		<Box as="form" onSubmit={handleSubmit(handleSubmitForm)} sx={styles.container}>
			<Flex sx={styles.calendarContainer}>
				<InputText
					label="Nome do planejamento"
					placeholder="Digite um nome de planejamento"
					name="nome"
					register={register}
					errorMessage={errors?.nome?.message}
					data-testid={`input--planningName`}
					maxLength={150}
					isEditing={isEditing && !!dirtyFields?.nome}
					sx={styles.input}
					required
				/>

				<Flex sx={styles.calendarContent}>
					<CustomCalendar
						label="Ano inicial:"
						placeholder="Selecione o ano inicial"
						fieldName="anoInicial"
						dataTestId="input--startYear"
						errorMessage={!startYearToFilter ? errors.anoInicial?.message : ''}
						setValue={(_field, data) => {
							setValue('anoInicial', data);
						}}
						minDate={new Date(startYearMinDate, 0, 1)}
						maxDate={new Date(startYearMaxDate, 0, 1)}
						control={control}
						// eslint-disable-next-line eqeqeq
						isEditing={isEditing && watch('anoInicial') != defaultValues?.anoInicial}
						required
						defaultValue={defaultValues?.anoInicial}
					/>

					<CustomCalendar
						label="Ano final:"
						placeholder="Selecione o ano final"
						fieldName="anoFinal"
						dataTestId="input--endYear"
						errorMessage={!finalYear ? errors.anoFinal?.message : ''}
						setValue={setValue}
						minDate={new Date(endYearMinDate, 0, 1)}
						maxDate={new Date(endYearMaxDate, 0, 1)}
						control={control}
						isDisabled={!startYearToFilter}
						// eslint-disable-next-line eqeqeq
						isEditing={isEditing && watch('anoFinal') != defaultValues?.anoFinal}
						required
						defaultValue={anoFinalDefaultValue}
					/>
				</Flex>
			</Flex>
			<Flex sx={styles.perspectiveContainer}>
				{fields.map((field, index) => (
					<Box key={index}>
						{fields.length >= 2 && (
							<>
								<Box sx={styles.perspectiveDividerContainer}>
									<Divider />
								</Box>
								<Flex onClick={() => handleDeletePerspective(field, index)} sx={styles.perspectiveIconContainer}>
									<XIcon />
								</Flex>
							</>
						)}
						<InputText
							label={!!index ? '' : 'Perspectiva(s)'}
							name={`perspectivas.${index}.perspectiva`}
							placeholder="Digite a perspectiva"
							register={register}
							errorMessage={errors?.perspectivas?.[index]?.perspectiva?.message}
							isEditing={isEditing && !!dirtyFields.perspectivas}
							required
							data-testid={`input--perspectivas-${index}`}
							maxLength={50}
							sx={styles.input}
							w="100%"
						/>
					</Box>
				))}
				<Button onClick={handleAddPerspective} sx={styles.perspectiveButton}>
					+ Adicionar Nova Perspectiva
				</Button>
			</Flex>
			<Box sx={styles.dividerContainer}>
				<Divider />
			</Box>
			<Box>
				<Flex sx={styles.textAreaContainer}>
					<InputTextarea
						sx={styles.textarea}
						register={register}
						placeholder="Descreva a missão"
						name="missao"
						label="Missão"
						data-testid="input--mission"
						required
						h="8.375rem"
						errorMessage={errors.missao?.message}
						isEditing={isEditing && !!dirtyFields.missao}
						maxLength={360}
					/>
					<InputTextarea
						sx={styles.textarea}
						register={register}
						placeholder="Descreva a visão"
						name="visao"
						label="Visão"
						data-testid="input--vision"
						required
						h="8.375rem"
						errorMessage={errors.visao?.message}
						isEditing={isEditing && !!dirtyFields.visao}
						maxLength={360}
					/>
				</Flex>
				<InputTextarea
					sx={styles.textarea}
					tooltipInfo="Incluir os valores separados por vírgulas"
					register={register}
					placeholder="Insira os valores"
					name="valor"
					label="Valor(es)"
					data-testid="input--values"
					required
					h="4.875rem"
					errorMessage={errors.valor?.message}
					isEditing={isEditing && !!dirtyFields.valor}
					maxLength={360}
				/>
			</Box>
			<Flex sx={styles.footer}>
				<Button type="submit" data-testid="button--submit" isLoading={isLoadingSubmit} sx={styles.footerButton}>
					Salvar
				</Button>
				<Button variant="secondary" sx={styles.footerButton} onClick={handleOpenModal}>
					Cancelar
				</Button>
			</Flex>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você realmente quer cancelar?"
				body="Todas as informações inseridas serão perdidas caso você cancele."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero cancelar',
						type: 'cancel',
						onClick: handleGoBack,
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleCloseModal,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<Prompt when={showPrompt} />
		</Box>
	);
};

export default StrategicPlanningForm;
